body {
  color: @text_color;
}

.btn.btn-primary {
	background-color: @color2;
}

.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn-group:focus .btn.btn-primary.dropdown-toggle {
	background-color: @color2_light;
}

.btn.btn-primary:active,
.btn-group.open .btn.btn-primary.dropdown-toggle,
.btn.btn-primary.active {
	background-color: @color2_dark;
}

.form-control {
  border-color: @gray;
}

.form-group.focus .form-control,
.form-control:focus {
	border-color: @color2;
}

a {
  color: @color2;
  &:hover {
	color: @color1;
  }

  &:active, &:focus {
	color: @color2;
  }

  &.disabled {
	pointer-events: none;
	color: @gray;
  }
}

.active .bottom-menu a, .bottom-menu a:hover, .bottom-menu a:focus {
	color: @color2;
}

.panel-primary {
	border-color: @color2;
}

.panel-primary > .panel-heading {
	border-color: @color2;
	background-color: @color2;
}

.ui-datepicker {
	border-color: @color2_dark;
}

.ui-datepicker:before {
	border-color: transparent transparent @color2_dark;
}

.ui-datepicker .ui-datepicker-header {
	background-color: @color2_dark;
}

.ui-datepicker .ui-datepicker-next, .ui-datepicker .ui-datepicker-prev {
	border-color: @color2_light;

	&.ui-state-disabled {
		.ui-icon {
			display: none;
		}
	}
}

.ui-datepicker .ui-datepicker-next:hover, .ui-datepicker .ui-datepicker-prev:hover {

	&:not(.ui-state-disabled) {
		background-color: @color2_light;
	}
}

.ui-datepicker-unselectable.ui-state-disabled span {
	color: @lightgray_dark;
}

.ui-datepicker .ui-state-active, .ui-datepicker .ui-state-active:hover {
	background-color: @color2_dark;
}

.progress-bar {
	background: @color2;
}

.pagination ul li > a:hover, .pagination ul li > span:hover {
    background-color: @color2;
}

.pagination ul li > a:active, .pagination ul li > span:active {
    background-color: @color2;
}

.label-primary {
	background-color: @color2;
}

.dropdown-menu li.active > a, .dropdown-menu li.selected > a, .dropdown-menu li.active > a.highlighted, .dropdown-menu li.selected > a.highlighted, .dropdown-inverse li.active > a, .dropdown-inverse li.selected > a {
	background: @color2;
	color: #ffffff;
}

.dropdown-menu li.active > a:hover, .dropdown-menu li.selected > a:hover, .dropdown-menu li.active > a.highlighted:hover, .dropdown-menu li.selected > a.highlighted:hover, .dropdown-menu li.active > a:focus, .dropdown-menu li.selected > a:focus, .dropdown-menu li.active > a.highlighted:focus, .dropdown-menu li.selected > a.highlighted:focus {
	background: @color2_light;
}

.form-group.focus .input-group-btn .btn-default {
	border-color: @color2 !important;
	color: @color2 !important;
}

.nav-pills > li > a {
	background-color: @color2;
	border-left-color: @color2_dark;
}

.nav-pills > li > a:hover {
	background-color: @color2_light;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover {
	background-color: @color2_dark;
}

.bottom-menu-inverse {
	background-color: @color1;
}



.tooltip-inner {
	background-color: @color1;

	a {
		color: @color2;
	}
}

.tooltip.top .tooltip-arrow {
	border-top-color: @color1;
}

.dropdown-inverse {
	background-color: @color1;
}

.dropdown-arrow-inverse {
	border-bottom-color: @color1 !important;
	border-top-color: @color1 !important;
}

.dropdown-inverse li > a:hover, .dropdown-inverse li > a:active, .dropdown-inverse li > a:focus {
	background: @color1_dark;
}

.pagination ul .pagination-dropdown ul li.active > a{
	background-color: @color2;
}

.pagination ul li.disabled > a, .pagination ul li.disabled > span, .pagination ul li.disabled > a, .pagination ul li.disabled > span {
    background-color: @lightgray !important;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  color: @color2;
}

.bottom-menu-inverse {
	color: @lightgray;
}

.checkbox.checked, .radio.checked {
    color: @color2;
}

.checkbox.checked .second-icon, .radio.checked .second-icon {
    color: @color2;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
	transition: 0.25s;
}

.input-group-btn.btn-file .btn.btn-primary {
    background-color: @color2;
    border-color: @color2;
    color: white;
}

.input-group-btn.btn-file .btn.btn-danger {
    background-color: #e74c3c;
    border-color: #e74c3c;
    color: white;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: @color1;
}

.tooltip.left .tooltip-arrow {
    border-left-color: @color1;
}

.tooltip.right .tooltip-arrow {
    border-right-color: @color1;
}

a.list-group-item {
  color: @text_color;
}

.tab-content {
  border-color: darken(@lightgray_dark, 10%);
}

.nav-tabs.nav-justified>.active>a, .nav-tabs.nav-justified>.active>a:hover, .nav-tabs.nav-justified>.active>a:focus {
  border-color: darken(@lightgray_dark, 10%);
}

.nav-tabs.nav-justified>.active>a, .nav-tabs.nav-justified>.active>a:hover, .nav-tabs.nav-justified>.active>a:focus {
  border-bottom-color: white;
}

.nav-tabs.nav-justified.nav-append-content > li.active:last-child:before {
  border-right-color: darken(@lightgray_dark, 10%);
}

.nav-tabs.nav-append-content > li.active:first-child:before {
  border-left-color: darken(@lightgray_dark, 10%);
}

select {
  &.form-control {
    &:disabled {
      color: @text_color;
      border-color: @gray;
      background-color: @lightgray;
      cursor: not-allowed;
    }
  }
}

hr {
	border-top-color: @lightgray_dark;
}