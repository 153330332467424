
/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
  font-family: 'Flat-UI-Icons';
  content: attr(data-icon);
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Use the following CSS code if you want to have a class per icon */
/*
Instead of a list of all class selectors,
you can use the generic selector below, but it's slower:
[class*="fui-"] {
*/
.fui-triangle-up,
.fui-triangle-up-small,
.fui-triangle-right-large,
.fui-triangle-left-large,
.fui-triangle-down,
.fui-info,
.fui-alert,
.fui-question,
.fui-window,
.fui-windows,
.fui-arrow-right,
.fui-arrow-left,
.fui-loop,
.fui-cmd,
.fui-mic,
.fui-triangle-down-small,
.fui-check-inverted,
.fui-heart,
.fui-location,
.fui-plus,
.fui-check,
.fui-cross,
.fui-list,
.fui-new,
.fui-video,
.fui-photo,
.fui-volume,
.fui-time,
.fui-eye,
.fui-chat,
.fui-home,
.fui-upload,
.fui-search,
.fui-user,
.fui-mail,
.fui-lock,
.fui-power,
.fui-star,
.fui-calendar,
.fui-gear,
.fui-book,
.fui-exit,
.fui-trash,
.fui-folder,
.fui-bubble,
.fui-cross-inverted,
.fui-plus-inverted,
.fui-export,
.fui-radio-unchecked,
.fui-radio-checked,
.fui-checkbox-unchecked,
.fui-checkbox-checked,
.fui-flat,
.fui-calendar-solid,
.fui-star-2,
.fui-credit-card,
.fui-clip,
.fui-link,
.fui-pause,
.fui-play,
.fui-tag,
.fui-document,
.fui-image,
.fui-facebook,
.fui-youtube,
.fui-vimeo,
.fui-twitter,
.fui-stumbleupon,
.fui-spotify,
.fui-skype,
.fui-pinterest,
.fui-path,
.fui-myspace,
.fui-linkedin,
.fui-googleplus,
.fui-dribbble,
.fui-blogger,
.fui-behance,
.fui-list-thumbnailed,
.fui-list-small-thumbnails,
.fui-list-numbered,
.fui-list-large-thumbnails,
.fui-list-columned,
.fui-list-bulleted {
  display: inline-block;
  font-family: 'Flat-UI-Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fui-triangle-up:before {
  content: "\e000";
}
.fui-triangle-up-small:before {
  content: "\e001";
}
.fui-triangle-right-large:before {
  content: "\e002";
}
.fui-triangle-left-large:before {
  content: "\e003";
}
.fui-triangle-down:before {
  content: "\e004";
}
.fui-info:before {
  content: "\e016";
}
.fui-alert:before {
  content: "\e017";
}
.fui-question:before {
  content: "\e018";
}
.fui-window:before {
  content: "\e019";
}
.fui-windows:before {
  content: "\e01a";
}
.fui-arrow-right:before {
  content: "\e02c";
}
.fui-arrow-left:before {
  content: "\e02d";
}
.fui-loop:before {
  content: "\e02e";
}
.fui-cmd:before {
  content: "\e02f";
}
.fui-mic:before {
  content: "\e030";
}
.fui-triangle-down-small:before {
  content: "\e005";
}
.fui-check-inverted:before {
  content: "\e006";
}
.fui-heart:before {
  content: "\e007";
}
.fui-location:before {
  content: "\e008";
}
.fui-plus:before {
  content: "\e009";
}
.fui-check:before {
  content: "\e00a";
}
.fui-cross:before {
  content: "\e00b";
}
.fui-list:before {
  content: "\e00c";
}
.fui-new:before {
  content: "\e00d";
}
.fui-video:before {
  content: "\e00e";
}
.fui-photo:before {
  content: "\e00f";
}
.fui-volume:before {
  content: "\e010";
}
.fui-time:before {
  content: "\e011";
}
.fui-eye:before {
  content: "\e012";
}
.fui-chat:before {
  content: "\e013";
}
.fui-home:before {
  content: "\e015";
}
.fui-upload:before {
  content: "\e01b";
}
.fui-search:before {
  content: "\e01c";
}
.fui-user:before {
  content: "\e01d";
}
.fui-mail:before {
  content: "\e01e";
}
.fui-lock:before {
  content: "\e01f";
}
.fui-power:before {
  content: "\e020";
}
.fui-star:before {
  content: "\e021";
}
.fui-calendar:before {
  content: "\e023";
}
.fui-gear:before {
  content: "\e024";
}
.fui-book:before {
  content: "\e025";
}
.fui-exit:before {
  content: "\e026";
}
.fui-trash:before {
  content: "\e027";
}
.fui-folder:before {
  content: "\e028";
}
.fui-bubble:before {
  content: "\e029";
}
.fui-cross-inverted:before {
  content: "\e02a";
}
.fui-plus-inverted:before {
  content: "\e031";
}
.fui-export:before {
  content: "\e014";
}
.fui-radio-unchecked:before {
  content: "\e02b";
}
.fui-radio-checked:before {
  content: "\e032";
}
.fui-checkbox-unchecked:before {
  content: "\e033";
}
.fui-checkbox-checked:before {
  content: "\e034";
}
.fui-flat:before {
  content: "\e035";
}
.fui-calendar-solid:before {
  content: "\e022";
}
.fui-star-2:before {
  content: "\e036";
}
.fui-credit-card:before {
  content: "\e037";
}
.fui-clip:before {
  content: "\e038";
}
.fui-link:before {
  content: "\e039";
}
.fui-pause:before {
  content: "\e03b";
}
.fui-play:before {
  content: "\e03c";
}
.fui-tag:before {
  content: "\e03d";
}
.fui-document:before {
  content: "\e03e";
}
.fui-image:before {
  content: "\e03a";
}
.fui-facebook:before {
  content: "\e03f";
}
.fui-youtube:before {
  content: "\e040";
}
.fui-vimeo:before {
  content: "\e041";
}
.fui-twitter:before {
  content: "\e042";
}
.fui-stumbleupon:before {
  content: "\e043";
}
.fui-spotify:before {
  content: "\e044";
}
.fui-skype:before {
  content: "\e045";
}
.fui-pinterest:before {
  content: "\e046";
}
.fui-path:before {
  content: "\e047";
}
.fui-myspace:before {
  content: "\e048";
}
.fui-linkedin:before {
  content: "\e049";
}
.fui-googleplus:before {
  content: "\e04a";
}
.fui-dribbble:before {
  content: "\e04c";
}
.fui-blogger:before {
  content: "\e04d";
}
.fui-behance:before {
  content: "\e04e";
}
.fui-list-thumbnailed:before {
  content: "\e04b";
}
.fui-list-small-thumbnails:before {
  content: "\e04f";
}
.fui-list-numbered:before {
  content: "\e050";
}
.fui-list-large-thumbnails:before {
  content: "\e051";
}
.fui-list-columned:before {
  content: "\e052";
}
.fui-list-bulleted:before {
  content: "\e053";
}
.dropdown-arrow-inverse {
  border-bottom-color: #34495e !important;
  border-top-color: #34495e !important;
}
body {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1.72222;
  color: #34495e;
  background-color: #ffffff;
}
a {
  color: #16a085;
  text-decoration: none;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}
a:hover,
a:focus {
  color: #1abc9c;
  text-decoration: none;
}
a:focus {
  outline: none;
}
.img-rounded {
  border-radius: 6px;
}
.img-thumbnail {
  padding: 4px;
  line-height: 1.72222;
  background-color: #ffffff;
  border: 2px solid #bdc3c7;
  border-radius: 6px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.img-comment {
  font-size: 15px;
  line-height: 1.2;
  font-style: italic;
  margin: 24px 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  font-weight: 700;
  line-height: 1.1;
  color: inherit;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small {
  color: #e7e9ec;
}
h1,
h2,
h3 {
  margin-top: 30px;
  margin-bottom: 15px;
}
h4,
h5,
h6 {
  margin-top: 15px;
  margin-bottom: 15px;
}
h6 {
  font-weight: normal;
}
h1,
.h1 {
  font-size: 61px;
}
h2,
.h2 {
  font-size: 53px;
}
h3,
.h3 {
  font-size: 40px;
}
h4,
.h4 {
  font-size: 29px;
}
h5,
.h5 {
  font-size: 28px;
}
h6,
.h6 {
  font-size: 24px;
}
p {
  font-size: 18px;
  line-height: 1.72222;
  margin: 0 0 15px;
}
.lead {
  margin-bottom: 30px;
  font-size: 28px;
  line-height: 1.46428571;
  font-weight: 300;
}
@media (min-width: 768px) {
  .lead {
    font-size: 30.006px;
  }
}
small,
.small {
  font-size: 83%;
  line-height: 2.067;
}
.text-muted {
  color: #bdc3c7;
}
.text-inverse {
  color: #ffffff;
}
.text-primary {
  color: #1abc9c;
}
a.text-primary:hover {
  color: #15967d;
}
.text-warning {
  color: #f1c40f;
}
a.text-warning:hover {
  color: #c19d0c;
}
.text-danger {
  color: #e74c3c;
}
a.text-danger:hover {
  color: #b93d30;
}
.text-success {
  color: #2ecc71;
}
a.text-success:hover {
  color: #25a35a;
}
.text-info {
  color: #3498db;
}
a.text-info:hover {
  color: #2a7aaf;
}
.bg-primary {
  color: #ffffff;
  background-color: #34495e;
}
a.bg-primary:hover {
  background-color: #222f3d;
}
.bg-success {
  background-color: #dff0d8;
}
a.bg-success:hover {
  background-color: #c1e2b3;
}
.bg-info {
  background-color: #d9edf7;
}
a.bg-info:hover {
  background-color: #afd9ee;
}
.bg-warning {
  background-color: #fcf8e3;
}
a.bg-warning:hover {
  background-color: #f7ecb5;
}
.bg-danger {
  background-color: #f2dede;
}
a.bg-danger:hover {
  background-color: #e4b9b9;
}
.page-header {
  padding-bottom: 14px;
  margin: 60px 0 30px;
  border-bottom: 1px solid #e7e9ec;
}
ul,
ol {
  margin-bottom: 15px;
}
dl {
  margin-bottom: 30px;
}
dt,
dd {
  line-height: 1.72222;
}
@media (min-width: 768px) {
  .dl-horizontal dt {
    width: 160px;
  }
  .dl-horizontal dd {
    margin-left: 180px;
  }
}
abbr[title],
abbr[data-original-title] {
  border-bottom: 1px dotted #bdc3c7;
}
blockquote {
  border-left: 3px solid #e7e9ec;
  padding: 0 0 0 16px;
  margin: 0 0 30px;
}
blockquote p {
  font-size: 20px;
  line-height: 1.55;
  font-weight: normal;
  margin-bottom: .4em;
}
blockquote small,
blockquote .small {
  font-size: 18px;
  line-height: 1.72222;
  font-style: italic;
  color: inherit;
}
blockquote small:before,
blockquote .small:before {
  content: "";
}
blockquote.pull-right {
  padding-right: 16px;
  padding-left: 0;
  border-right: 3px solid #e7e9ec;
  border-left: 0;
}
blockquote.pull-right small:after {
  content: "";
}
address {
  margin-bottom: 30px;
  line-height: 1.72222;
}
code,
kbd,
pre,
samp {
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
}
code {
  padding: 2px 6px;
  font-size: 85%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
kbd {
  padding: 2px 6px;
  font-size: 85%;
  color: #ffffff;
  background-color: #34495e;
  border-radius: 4px;
  box-shadow: none;
}
pre {
  padding: 8px;
  margin: 0 0 15px;
  font-size: 13px;
  line-height: 1.72222;
  color: inherit;
  background-color: #ffffff;
  border: 2px solid #e7e9ec;
  border-radius: 6px;
  white-space: pre;
}
.pre-scrollable {
  max-height: 340px;
}
.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 5px;
  line-height: 1.72222;
  background-color: #ffffff;
  border: 2px solid #bdc3c7;
  border-radius: 6px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.thumbnail > img,
.thumbnail a > img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  border-color: #16a085;
}
.thumbnail .caption {
  padding: 9px;
  color: #34495e;
}
.btn {
  border: none;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.4;
  border-radius: 4px;
  padding: 10px 15px;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
  transition: border .25s linear, color .25s linear, background-color .25s linear;
}
.btn:hover,
.btn:focus {
  outline: none;
  color: #ffffff;
}
.btn:active,
.btn.active {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  background-color: #bdc3c7;
  color: rgba(255, 255, 255, 0.75);
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.btn > [class^="fui-"] {
  margin: 0 1px;
  position: relative;
  line-height: 1;
  top: 1px;
}
.btn-xs.btn > [class^="fui-"] {
  font-size: 11px;
  top: 0;
}
.btn-hg.btn > [class^="fui-"] {
  top: 2px;
}
.btn-default {
  color: #ffffff;
  background-color: #bdc3c7;
}
.btn-default:hover,
.btn-default.hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  color: #ffffff;
  background-color: #cacfd2;
  border-color: #cacfd2;
}
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background: #a1a6a9;
  border-color: #a1a6a9;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled.hover,
.btn-default[disabled].hover,
fieldset[disabled] .btn-default.hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: #bdc3c7;
  border-color: #bdc3c7;
}
.btn-default .badge {
  color: #bdc3c7;
  background-color: #ffffff;
}
.btn-primary {
  color: #ffffff;
  background-color: #1abc9c;
}
.btn-primary:hover,
.btn-primary.hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #ffffff;
  background-color: #48c9b0;
  border-color: #48c9b0;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background: #16a085;
  border-color: #16a085;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled.hover,
.btn-primary[disabled].hover,
fieldset[disabled] .btn-primary.hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #1abc9c;
  border-color: #1abc9c;
}
.btn-primary .badge {
  color: #1abc9c;
  background-color: #ffffff;
}
.btn-info {
  color: #ffffff;
  background-color: #3498db;
}
.btn-info:hover,
.btn-info.hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  color: #ffffff;
  background-color: #5dade2;
  border-color: #5dade2;
}
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background: #2c81ba;
  border-color: #2c81ba;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled.hover,
.btn-info[disabled].hover,
fieldset[disabled] .btn-info.hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #3498db;
  border-color: #3498db;
}
.btn-info .badge {
  color: #3498db;
  background-color: #ffffff;
}
.btn-danger {
  color: #ffffff;
  background-color: #e74c3c;
}
.btn-danger:hover,
.btn-danger.hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  color: #ffffff;
  background-color: #ec7063;
  border-color: #ec7063;
}
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background: #c44133;
  border-color: #c44133;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled.hover,
.btn-danger[disabled].hover,
fieldset[disabled] .btn-danger.hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  background-color: #e74c3c;
  border-color: #e74c3c;
}
.btn-danger .badge {
  color: #e74c3c;
  background-color: #ffffff;
}
.btn-success {
  color: #ffffff;
  background-color: #2ecc71;
}
.btn-success:hover,
.btn-success.hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  color: #ffffff;
  background-color: #58d68d;
  border-color: #58d68d;
}
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background: #27ad60;
  border-color: #27ad60;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled.hover,
.btn-success[disabled].hover,
fieldset[disabled] .btn-success.hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  background-color: #2ecc71;
  border-color: #2ecc71;
}
.btn-success .badge {
  color: #2ecc71;
  background-color: #ffffff;
}
.btn-warning {
  color: #ffffff;
  background-color: #f1c40f;
}
.btn-warning:hover,
.btn-warning.hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  color: #ffffff;
  background-color: #f5d313;
  border-color: #f5d313;
}
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background: #cda70d;
  border-color: #cda70d;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled.hover,
.btn-warning[disabled].hover,
fieldset[disabled] .btn-warning.hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  background-color: #f1c40f;
  border-color: #f1c40f;
}
.btn-warning .badge {
  color: #f1c40f;
  background-color: #ffffff;
}
.btn-inverse {
  color: #ffffff;
  background-color: #34495e;
}
.btn-inverse:hover,
.btn-inverse.hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.open .dropdown-toggle.btn-inverse {
  color: #ffffff;
  background-color: #415b76;
  border-color: #415b76;
}
.btn-inverse:active,
.btn-inverse.active,
.open .dropdown-toggle.btn-inverse {
  background: #2c3e50;
  border-color: #2c3e50;
}
.btn-inverse.disabled,
.btn-inverse[disabled],
fieldset[disabled] .btn-inverse,
.btn-inverse.disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse:hover,
.btn-inverse.disabled.hover,
.btn-inverse[disabled].hover,
fieldset[disabled] .btn-inverse.hover,
.btn-inverse.disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled] .btn-inverse:focus,
.btn-inverse.disabled:active,
.btn-inverse[disabled]:active,
fieldset[disabled] .btn-inverse:active,
.btn-inverse.disabled.active,
.btn-inverse[disabled].active,
fieldset[disabled] .btn-inverse.active {
  background-color: #34495e;
  border-color: #34495e;
}
.btn-inverse .badge {
  color: #34495e;
  background-color: #ffffff;
}
.btn-embossed {
  -webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
}
.btn-embossed.active,
.btn-embossed:active {
  -webkit-box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.15);
}
.btn-wide {
  min-width: 140px;
  padding-left: 30px;
  padding-right: 30px;
}
.btn-link {
  color: #16a085;
}
.btn-link:hover,
.btn-link:focus {
  color: #1abc9c;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
  color: #bdc3c7;
  text-decoration: none;
}
.btn-hg {
  padding: 13px 20px;
  font-size: 22px;
  line-height: 1.227;
  border-radius: 6px;
}
.btn-lg {
  padding: 10px 19px;
  font-size: 17px;
  line-height: 1.471;
  border-radius: 6px;
}
.btn-sm {
  padding: 9px 13px;
  font-size: 13px;
  line-height: 1.385;
  border-radius: 4px;
}
.btn-xs {
  padding: 6px 9px;
  font-size: 12px;
  line-height: 1.083;
  border-radius: 3px;
}
.btn-tip {
  font-weight: 300;
  padding-left: 10px;
  font-size: 92%;
}
.btn-block {
  white-space: normal;
}
[class*="btn-social-"] {
  padding: 10px 15px;
  font-size: 13px;
  line-height: 1.077;
  border-radius: 4px;
}
.btn-social-pinterest {
  color: #ffffff;
  background-color: #cb2028;
}
.btn-social-pinterest:hover,
.btn-social-pinterest:focus {
  background-color: #d54d53;
}
.btn-social-pinterest:active,
.btn-social-pinterest.active {
  background-color: #ad1b22;
}
.btn-social-linkedin {
  color: #ffffff;
  background-color: #0072b5;
}
.btn-social-linkedin:hover,
.btn-social-linkedin:focus {
  background-color: #338ec4;
}
.btn-social-linkedin:active,
.btn-social-linkedin.active {
  background-color: #00619a;
}
.btn-social-stumbleupon {
  color: #ffffff;
  background-color: #ed4a13;
}
.btn-social-stumbleupon:hover,
.btn-social-stumbleupon:focus {
  background-color: #f16e42;
}
.btn-social-stumbleupon:active,
.btn-social-stumbleupon.active {
  background-color: #c93f10;
}
.btn-social-googleplus {
  color: #ffffff;
  background-color: #2d2d2d;
}
.btn-social-googleplus:hover,
.btn-social-googleplus:focus {
  background-color: #575757;
}
.btn-social-googleplus:active,
.btn-social-googleplus.active {
  background-color: #262626;
}
.btn-social-facebook {
  color: #ffffff;
  background-color: #2f4b93;
}
.btn-social-facebook:hover,
.btn-social-facebook:focus {
  background-color: #596fa9;
}
.btn-social-facebook:active,
.btn-social-facebook.active {
  background-color: #28407d;
}
.btn-social-twitter {
  color: #ffffff;
  background-color: #00bdef;
}
.btn-social-twitter:hover,
.btn-social-twitter:focus {
  background-color: #33caf2;
}
.btn-social-twitter:active,
.btn-social-twitter.active {
  background-color: #00a1cb;
}
.btn-default .caret {
  border-top-color: #ffffff;
}
.btn-primary .caret,
.btn-success .caret,
.btn-warning .caret,
.btn-danger .caret,
.btn-info .caret {
  border-top-color: #ffffff;
}
.dropup .btn-default .caret {
  border-bottom-color: #ffffff;
}
.dropup .btn-primary .caret,
.dropup .btn-success .caret,
.dropup .btn-warning .caret,
.dropup .btn-danger .caret,
.dropup .btn-info .caret {
  border-bottom-color: #ffffff;
}
.btn-group-xs > .btn {
  padding: 6px 9px;
  font-size: 12px;
  line-height: 1.083;
  border-radius: 3px;
}
.btn-group-sm > .btn {
  padding: 9px 13px;
  font-size: 13px;
  line-height: 1.385;
  border-radius: 4px;
}
.btn-group-lg > .btn {
  padding: 10px 19px;
  font-size: 17px;
  line-height: 1.471;
  border-radius: 6px;
}
.btn-group-gh > .btn {
  padding: 13px 20px;
  font-size: 22px;
  line-height: 1.227;
  border-radius: 6px;
}
.btn-group > .btn + .btn {
  margin-left: 0;
}
.btn-group > .btn + .dropdown-toggle {
  border-left: 2px solid rgba(52, 73, 94, 0.15);
  padding-left: 12px;
  padding-right: 12px;
}
.btn-group > .btn + .dropdown-toggle .caret {
  margin-left: 3px;
  margin-right: 3px;
}
.btn-group > .btn.btn-gh + .dropdown-toggle .caret {
  margin-left: 7px;
  margin-right: 7px;
}
.btn-group > .btn.btn-sm + .dropdown-toggle .caret {
  margin-left: 0;
  margin-right: 0;
}
.dropdown-toggle .caret {
  margin-left: 8px;
}
.btn-group > .btn,
.btn-group > .dropdown-menu,
.btn-group > .popover {
  font-weight: 400;
}
.btn-group:focus .dropdown-toggle {
  outline: none;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}
.btn-group.open .dropdown-toggle {
  color: rgba(255, 255, 255, 0.75);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-toolbar .btn.active {
  color: #ffffff;
}
.btn-toolbar .btn > [class^="fui-"] {
  font-size: 16px;
  margin: 0 1px;
}
.caret {
  border-width: 8px 6px;
  border-bottom-color: #34495e;
  border-top-color: #34495e;
  border-style: solid;
  border-bottom-style: none;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  -webkit-transform: scale(1.001, );
  -ms-transform: scale(1.001, );
  transform: scale(1.001, );
}
.dropup .caret,
.dropup .btn-lg .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-bottom-width: 8px;
}
.btn-lg .caret {
  border-top-width: 8px;
  border-right-width: 6px;
  border-left-width: 6px;
}
.select {
  display: inline-block;
  margin-bottom: 10px;
}
[class*="span"] > .select[class*="span"] {
  margin-left: 0;
}
.select[class*="span"] .btn {
  width: 100%;
}
.select.select-block {
  display: block;
  float: none;
  margin-left: 0;
  width: auto;
}
.select.select-block:before,
.select.select-block:after {
  content: " ";
  /* 1 */

  display: table;
  /* 2 */

}
.select.select-block:after {
  clear: both;
}
.select.select-block .btn {
  width: 100%;
}
.select.select-block .dropdown-menu {
  width: 100%;
}
.select .btn {
  width: 220px;
}
.select .btn.btn-hg .filter-option {
  left: 20px;
  right: 40px;
  top: 13px;
}
.select .btn.btn-hg .caret {
  right: 20px;
}
.select .btn.btn-lg .filter-option {
  left: 18px;
  right: 38px;
}
.select .btn.btn-sm .filter-option {
  left: 13px;
  right: 33px;
}
.select .btn.btn-sm .caret {
  right: 13px;
}
.select .btn.btn-xs .filter-option {
  left: 13px;
  right: 33px;
  top: 5px;
}
.select .btn.btn-xs .caret {
  right: 13px;
}
.select .btn .filter-option {
  height: 26px;
  left: 13px;
  overflow: hidden;
  position: absolute;
  right: 33px;
  text-align: left;
  top: 10px;
}
.select .btn .caret {
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -3px;
}
.select .btn .dropdown-toggle {
  border-radius: 6px;
}
.select .btn .dropdown-menu {
  min-width: 100%;
}
.select .btn .dropdown-menu dt {
  cursor: default;
  display: block;
  padding: 3px 20px;
}
.select .btn .dropdown-menu li:not(.disabled) > a:hover small {
  color: rgba(255, 255, 255, 0.004);
}
.select .btn .dropdown-menu li > a {
  min-height: 20px;
}
.select .btn .dropdown-menu li > a.opt {
  padding-left: 35px;
}
.select .btn .dropdown-menu li small {
  padding-left: .5em;
}
.select .btn .dropdown-menu li > dt small {
  font-weight: normal;
}
.select .btn > .disabled,
.select .btn .dropdown-menu li.disabled > a {
  cursor: default;
}
.select .caret {
  border-bottom-color: #ffffff;
  border-top-color: #ffffff;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 15px;
  font-size: 24px;
  line-height: inherit;
  color: inherit;
  border-bottom: none;
}
textarea {
  font-size: 20px;
  line-height: 24px;
  padding: 5px 11px;
}
input[type="search"] {
  -webkit-appearance: none !important;
}
label {
  font-weight: normal;
  font-size: 15px;
  line-height: 2.4;
}
.form-control:-moz-placeholder {
  color: #b2bcc5;
}
.form-control::-moz-placeholder {
  color: #b2bcc5;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #b2bcc5;
}
.form-control::-webkit-input-placeholder {
  color: #b2bcc5;
}
.form-control.placeholder {
  color: #b2bcc5;
}
.form-control {
  border: 2px solid #bdc3c7;
  color: #34495e;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 1.467;
  padding: 8px 12px;
  height: 42px;
  -webkit-appearance: none;
  border-radius: 6px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
  transition: border .25s linear, color .25s linear, background-color .25s linear;
}
.form-group.focus .form-control,
.form-control:focus {
  border-color: #1abc9c;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #f4f6f6;
  border-color: #d5dbdb;
  color: #d5dbdb;
  cursor: default;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.form-control.flat {
  border-color: transparent;
}
.form-control.flat:hover {
  border-color: #bdc3c7;
}
.form-control.flat:focus {
  border-color: #1abc9c;
}
.input-sm {
  height: 35px;
  padding: 6px 10px;
  font-size: 13px;
  line-height: 1.462;
  border-radius: 6px;
}
select.input-sm {
  height: 35px;
  line-height: 35px;
}
textarea.input-sm,
select[multiple].input-sm {
  height: auto;
}
.input-lg {
  height: 45px;
  padding: 10px 15px;
  font-size: 17px;
  line-height: 1.235;
  border-radius: 6px;
}
select.input-lg {
  height: 45px;
  line-height: 45px;
}
textarea.input-lg,
select[multiple].input-lg {
  height: auto;
}
.input-hg {
  height: 53px;
  padding: 10px 16px;
  font-size: 22px;
  line-height: 1.318;
  border-radius: 6px;
}
select.input-hg {
  height: 53px;
  line-height: 53px;
}
textarea.input-hg,
select[multiple].input-hg {
  height: auto;
}
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
  color: #f1c40f;
}
.has-warning .form-control {
  color: #f1c40f;
  border-color: #f1c40f;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-warning .form-control:-moz-placeholder {
  color: #f1c40f;
}
.has-warning .form-control::-moz-placeholder {
  color: #f1c40f;
  opacity: 1;
}
.has-warning .form-control:-ms-input-placeholder {
  color: #f1c40f;
}
.has-warning .form-control::-webkit-input-placeholder {
  color: #f1c40f;
}
.has-warning .form-control.placeholder {
  color: #f1c40f;
}
.has-warning .form-control:focus {
  border-color: #f1c40f;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-warning .input-group-addon {
  color: #f1c40f;
  border-color: #f1c40f;
  background-color: #ffffff;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #e74c3c;
}
.has-error .form-control {
  color: #e74c3c;
  border-color: #e74c3c;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-error .form-control:-moz-placeholder {
  color: #e74c3c;
}
.has-error .form-control::-moz-placeholder {
  color: #e74c3c;
  opacity: 1;
}
.has-error .form-control:-ms-input-placeholder {
  color: #e74c3c;
}
.has-error .form-control::-webkit-input-placeholder {
  color: #e74c3c;
}
.has-error .form-control.placeholder {
  color: #e74c3c;
}
.has-error .form-control:focus {
  border-color: #e74c3c;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-error .input-group-addon {
  color: #e74c3c;
  border-color: #e74c3c;
  background-color: #ffffff;
}
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
  color: #2ecc71;
}
.has-success .form-control {
  color: #2ecc71;
  border-color: #2ecc71;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-success .form-control:-moz-placeholder {
  color: #2ecc71;
}
.has-success .form-control::-moz-placeholder {
  color: #2ecc71;
  opacity: 1;
}
.has-success .form-control:-ms-input-placeholder {
  color: #2ecc71;
}
.has-success .form-control::-webkit-input-placeholder {
  color: #2ecc71;
}
.has-success .form-control.placeholder {
  color: #2ecc71;
}
.has-success .form-control:focus {
  border-color: #2ecc71;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-success .input-group-addon {
  color: #2ecc71;
  border-color: #2ecc71;
  background-color: #ffffff;
}
.help-block {
  font-size: 15px;
  margin-bottom: 5px;
  color: inherit;
}
.form-group {
  position: relative;
  margin-bottom: 20px;
}
.form-horizontal .control-label,
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 6px;
}
.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px;
}
.form-horizontal .form-group:before,
.form-horizontal .form-group:after {
  content: " ";
  /* 1 */

  display: table;
  /* 2 */

}
.form-horizontal .form-group:after {
  clear: both;
}
.form-horizontal .form-control-static {
  padding-top: 6px;
}
.form-group {
  position: relative;
}
.form-control + .input-icon {
  position: absolute;
  top: 2px;
  right: 2px;
  line-height: 37px;
  vertical-align: middle;
  font-size: 20px;
  color: #b2bcc5;
  background-color: #ffffff;
  padding: 0 12px 0 0;
  border-radius: 6px;
}
.input-hg + .input-icon {
  line-height: 49px;
  padding: 0 16px 0 0;
}
.input-lg + .input-icon {
  line-height: 41px;
  padding: 0 15px 0 0;
}
.input-sm + .input-icon {
  font-size: 18px;
  line-height: 30px;
  padding: 0 10px 0 0;
}
.has-success .input-icon {
  color: #2ecc71;
}
.has-warning .input-icon {
  color: #f1c40f;
}
.has-error .input-icon {
  color: #e74c3c;
}
.form-control[disabled] + .input-icon,
.form-control[readonly] + .input-icon,
fieldset[disabled] .form-control + .input-icon,
.form-control.disabled + .input-icon {
  color: #d5dbdb;
  background-color: transparent;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.input-group-hg > .form-control,
.input-group-hg > .input-group-addon,
.input-group-hg > .input-group-btn > .btn {
  height: 53px;
  padding: 10px 16px;
  font-size: 22px;
  line-height: 1.318;
  border-radius: 6px;
}
select.input-group-hg > .form-control,
select.input-group-hg > .input-group-addon,
select.input-group-hg > .input-group-btn > .btn {
  height: 53px;
  line-height: 53px;
}
textarea.input-group-hg > .form-control,
textarea.input-group-hg > .input-group-addon,
textarea.input-group-hg > .input-group-btn > .btn,
select[multiple].input-group-hg > .form-control,
select[multiple].input-group-hg > .input-group-addon,
select[multiple].input-group-hg > .input-group-btn > .btn {
  height: auto;
}
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  height: 45px;
  padding: 10px 15px;
  font-size: 17px;
  line-height: 1.235;
  border-radius: 6px;
}
select.input-group-lg > .form-control,
select.input-group-lg > .input-group-addon,
select.input-group-lg > .input-group-btn > .btn {
  height: 45px;
  line-height: 45px;
}
textarea.input-group-lg > .form-control,
textarea.input-group-lg > .input-group-addon,
textarea.input-group-lg > .input-group-btn > .btn,
select[multiple].input-group-lg > .form-control,
select[multiple].input-group-lg > .input-group-addon,
select[multiple].input-group-lg > .input-group-btn > .btn {
  height: auto;
}
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  height: 35px;
  padding: 6px 10px;
  font-size: 13px;
  line-height: 1.462;
  border-radius: 6px;
}
select.input-group-sm > .form-control,
select.input-group-sm > .input-group-addon,
select.input-group-sm > .input-group-btn > .btn {
  height: 35px;
  line-height: 35px;
}
textarea.input-group-sm > .form-control,
textarea.input-group-sm > .input-group-addon,
textarea.input-group-sm > .input-group-btn > .btn,
select[multiple].input-group-sm > .form-control,
select[multiple].input-group-sm > .input-group-addon,
select[multiple].input-group-sm > .input-group-btn > .btn {
  height: auto;
}
.input-group-addon {
  padding: 10px 12px;
  font-size: 15px;
  color: #ffffff;
  text-align: center;
  background-color: #bdc3c7;
  border: 1px solid #bdc3c7;
  border-radius: 6px;
  -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
  transition: border .25s linear, color .25s linear, background-color .25s linear;
}
.input-group-hg .input-group-addon,
.input-group-lg .input-group-addon,
.input-group-sm .input-group-addon {
  line-height: 1;
}
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.form-group.focus .input-group-addon,
.input-group.focus .input-group-addon {
  background-color: #1abc9c;
  border-color: #1abc9c;
}
.form-group.focus .input-group-btn > .btn-default + .btn-default,
.input-group.focus .input-group-btn > .btn-default + .btn-default {
  border-left-color: #16a085;
}
.form-group.focus .input-group-btn .btn,
.input-group.focus .input-group-btn .btn {
  border-color: #1abc9c;
  background-color: #ffffff;
  color: #1abc9c;
}
.form-group.focus .input-group-btn .btn-default,
.input-group.focus .input-group-btn .btn-default {
  color: #ffffff;
  background-color: #1abc9c;
}
.form-group.focus .input-group-btn .btn-default:hover,
.input-group.focus .input-group-btn .btn-default:hover,
.form-group.focus .input-group-btn .btn-default.hover,
.input-group.focus .input-group-btn .btn-default.hover,
.form-group.focus .input-group-btn .btn-default:focus,
.input-group.focus .input-group-btn .btn-default:focus,
.form-group.focus .input-group-btn .btn-default:active,
.input-group.focus .input-group-btn .btn-default:active,
.form-group.focus .input-group-btn .btn-default.active,
.input-group.focus .input-group-btn .btn-default.active,
.open .dropdown-toggle.form-group.focus .input-group-btn .btn-default,
.open .dropdown-toggle.input-group.focus .input-group-btn .btn-default {
  color: #ffffff;
  background-color: #48c9b0;
  border-color: #48c9b0;
}
.form-group.focus .input-group-btn .btn-default:active,
.input-group.focus .input-group-btn .btn-default:active,
.form-group.focus .input-group-btn .btn-default.active,
.input-group.focus .input-group-btn .btn-default.active,
.open .dropdown-toggle.form-group.focus .input-group-btn .btn-default,
.open .dropdown-toggle.input-group.focus .input-group-btn .btn-default {
  background: #16a085;
  border-color: #16a085;
}
.form-group.focus .input-group-btn .btn-default.disabled,
.input-group.focus .input-group-btn .btn-default.disabled,
.form-group.focus .input-group-btn .btn-default[disabled],
.input-group.focus .input-group-btn .btn-default[disabled],
fieldset[disabled] .form-group.focus .input-group-btn .btn-default,
fieldset[disabled] .input-group.focus .input-group-btn .btn-default,
.form-group.focus .input-group-btn .btn-default.disabled:hover,
.input-group.focus .input-group-btn .btn-default.disabled:hover,
.form-group.focus .input-group-btn .btn-default[disabled]:hover,
.input-group.focus .input-group-btn .btn-default[disabled]:hover,
fieldset[disabled] .form-group.focus .input-group-btn .btn-default:hover,
fieldset[disabled] .input-group.focus .input-group-btn .btn-default:hover,
.form-group.focus .input-group-btn .btn-default.disabled.hover,
.input-group.focus .input-group-btn .btn-default.disabled.hover,
.form-group.focus .input-group-btn .btn-default[disabled].hover,
.input-group.focus .input-group-btn .btn-default[disabled].hover,
fieldset[disabled] .form-group.focus .input-group-btn .btn-default.hover,
fieldset[disabled] .input-group.focus .input-group-btn .btn-default.hover,
.form-group.focus .input-group-btn .btn-default.disabled:focus,
.input-group.focus .input-group-btn .btn-default.disabled:focus,
.form-group.focus .input-group-btn .btn-default[disabled]:focus,
.input-group.focus .input-group-btn .btn-default[disabled]:focus,
fieldset[disabled] .form-group.focus .input-group-btn .btn-default:focus,
fieldset[disabled] .input-group.focus .input-group-btn .btn-default:focus,
.form-group.focus .input-group-btn .btn-default.disabled:active,
.input-group.focus .input-group-btn .btn-default.disabled:active,
.form-group.focus .input-group-btn .btn-default[disabled]:active,
.input-group.focus .input-group-btn .btn-default[disabled]:active,
fieldset[disabled] .form-group.focus .input-group-btn .btn-default:active,
fieldset[disabled] .input-group.focus .input-group-btn .btn-default:active,
.form-group.focus .input-group-btn .btn-default.disabled.active,
.input-group.focus .input-group-btn .btn-default.disabled.active,
.form-group.focus .input-group-btn .btn-default[disabled].active,
.input-group.focus .input-group-btn .btn-default[disabled].active,
fieldset[disabled] .form-group.focus .input-group-btn .btn-default.active,
fieldset[disabled] .input-group.focus .input-group-btn .btn-default.active {
  background-color: #1abc9c;
  border-color: #1abc9c;
}
.form-group.focus .input-group-btn .btn-default .badge,
.input-group.focus .input-group-btn .btn-default .badge {
  color: #1abc9c;
  background-color: #ffffff;
}
.input-group-btn .btn {
  background-color: #ffffff;
  border: 2px solid #bdc3c7;
  color: #bdc3c7;
  line-height: 18px;
}
.input-group-btn .btn-default {
  color: #ffffff;
  background-color: #bdc3c7;
}
.input-group-btn .btn-default:hover,
.input-group-btn .btn-default.hover,
.input-group-btn .btn-default:focus,
.input-group-btn .btn-default:active,
.input-group-btn .btn-default.active,
.open .dropdown-toggle.input-group-btn .btn-default {
  color: #ffffff;
  background-color: #cacfd2;
  border-color: #cacfd2;
}
.input-group-btn .btn-default:active,
.input-group-btn .btn-default.active,
.open .dropdown-toggle.input-group-btn .btn-default {
  background: #a1a6a9;
  border-color: #a1a6a9;
}
.input-group-btn .btn-default.disabled,
.input-group-btn .btn-default[disabled],
fieldset[disabled] .input-group-btn .btn-default,
.input-group-btn .btn-default.disabled:hover,
.input-group-btn .btn-default[disabled]:hover,
fieldset[disabled] .input-group-btn .btn-default:hover,
.input-group-btn .btn-default.disabled.hover,
.input-group-btn .btn-default[disabled].hover,
fieldset[disabled] .input-group-btn .btn-default.hover,
.input-group-btn .btn-default.disabled:focus,
.input-group-btn .btn-default[disabled]:focus,
fieldset[disabled] .input-group-btn .btn-default:focus,
.input-group-btn .btn-default.disabled:active,
.input-group-btn .btn-default[disabled]:active,
fieldset[disabled] .input-group-btn .btn-default:active,
.input-group-btn .btn-default.disabled.active,
.input-group-btn .btn-default[disabled].active,
fieldset[disabled] .input-group-btn .btn-default.active {
  background-color: #bdc3c7;
  border-color: #bdc3c7;
}
.input-group-btn .btn-default .badge {
  color: #bdc3c7;
  background-color: #ffffff;
}
.input-group-hg .input-group-btn .btn {
  line-height: 31px;
}
.input-group-lg .input-group-btn .btn {
  line-height: 21px;
}
.input-group-sm .input-group-btn .btn {
  line-height: 19px;
}
.input-group-btn:first-child > .btn {
  border-right-width: 0;
  margin-right: -2px;
}
.input-group-btn:last-child > .btn {
  border-left-width: 0;
  margin-left: -2px;
}
.input-group-btn > .btn-default + .btn-default {
  border-left: 2px solid #bdc3c7;
}
.input-group-btn > .btn:first-child + .btn .caret {
  margin-left: 0;
}
.input-group-rounded .input-group-btn + .form-control,
.input-group-rounded .input-group-btn:last-child .btn {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
.input-group-hg.input-group-rounded .input-group-btn + .form-control,
.input-group-hg.input-group-rounded .input-group-btn:last-child .btn {
  border-bottom-right-radius: 27px;
  border-top-right-radius: 27px;
}
.input-group-lg.input-group-rounded .input-group-btn + .form-control,
.input-group-lg.input-group-rounded .input-group-btn:last-child .btn {
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}
.input-group-rounded .form-control:first-child,
.input-group-rounded .input-group-btn:first-child .btn {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
.input-group-hg.input-group-rounded .form-control:first-child,
.input-group-hg.input-group-rounded .input-group-btn:first-child .btn {
  border-bottom-left-radius: 27px;
  border-top-left-radius: 27px;
}
.input-group-lg.input-group-rounded .form-control:first-child,
.input-group-lg.input-group-rounded .input-group-btn:first-child .btn {
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
}
.input-group-rounded .input-group-btn + .form-control {
  padding-left: 0;
}
.btn-file {
  overflow: hidden;
  position: relative;
}
.btn-file > input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 23px;
  direction: ltr;
  cursor: pointer;
  width: 100%;
}
.fileinput {
  margin-bottom: 9px;
  display: block;
}
.fileinput .fileinput-filename {
  font-size: 15px;
}
.fileinput .uneditable-input {
  display: inline-block;
  margin-bottom: 0px;
  vertical-align: middle;
  cursor: text;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fileinput .uneditable-input [class^="fui-"] {
  margin-right: .3em;
}
.fileinput .fileinput-preview {
  cursor: pointer;
}
.fileinput .thumbnail {
  overflow: hidden;
  display: inline-block;
}
.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none;
}
.fileinput-inline .fileinput-controls {
  display: inline;
}
.fileinput-new .input-group .btn-file {
  border-radius: 0 4px 4px 0;
}
.fileinput-new .input-group .btn-file.btn-xs,
.fileinput-new .input-group .btn-file.btn-sm {
  border-radius: 0 3px 3px 0;
}
.fileinput-new .input-group .btn-file.btn-lg {
  border-radius: 0 6px 6px 0;
}
.form-group.has-warning .fileinput .uneditable-input {
  color: #f1c40f;
  border-color: #faebcc;
}
.form-group.has-warning .fileinput .fileinput-preview {
  color: #f1c40f;
}
.form-group.has-warning .fileinput .thumbnail {
  border-color: #faebcc;
}
.form-group.has-error .fileinput .uneditable-input {
  color: #e74c3c;
  border-color: #ebccd1;
}
.form-group.has-error .fileinput .fileinput-preview {
  color: #e74c3c;
}
.form-group.has-error .fileinput .thumbnail {
  border-color: #ebccd1;
}
.form-group.has-success .fileinput .uneditable-input {
  color: #2ecc71;
  border-color: #d6e9c6;
}
.form-group.has-success .fileinput .fileinput-preview {
  color: #2ecc71;
}
.form-group.has-success .fileinput .thumbnail {
  border-color: #d6e9c6;
}
.input-group-addon:not(:first-child) {
  border-left: 0;
}
.checkbox,
.radio {
  margin-bottom: 12px;
  padding-left: 32px;
  position: relative;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
  font-size: 14px;
  line-height: 1.5;
}
.checkbox input,
.radio input {
  outline: none !important;
  display: none;
}
.checkbox .icons,
.radio .icons {
  color: #bdc3c7;
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
  text-align: center;
  line-height: 21px;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
.checkbox .icons .first-icon,
.radio .icons .first-icon,
.checkbox .icons .second-icon,
.radio .icons .second-icon {
  display: inline-table;
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  margin: 0;
  opacity: 1;
  filter: alpha(opacity=100);
}
.checkbox .icons .second-icon,
.radio .icons .second-icon {
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox:hover,
.radio:hover {
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
.checkbox:hover .first-icon,
.radio:hover .first-icon {
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox:hover .second-icon,
.radio:hover .second-icon {
  opacity: 1;
  filter: alpha(opacity=100);
}
.checkbox.checked,
.radio.checked {
  color: #1abc9c;
}
.checkbox.checked .first-icon,
.radio.checked .first-icon {
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox.checked .second-icon,
.radio.checked .second-icon {
  opacity: 1;
  filter: alpha(opacity=100);
  color: #1abc9c;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
.checkbox.disabled,
.radio.disabled {
  cursor: default;
  color: #e6e8ea;
}
.checkbox.disabled .icons,
.radio.disabled .icons {
  color: #e6e8ea;
}
.checkbox.disabled .first-icon,
.radio.disabled .first-icon {
  opacity: 1;
  filter: alpha(opacity=100);
}
.checkbox.disabled .second-icon,
.radio.disabled .second-icon {
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox.disabled.checked .icons,
.radio.disabled.checked .icons {
  color: #e6e8ea;
}
.checkbox.disabled.checked .first-icon,
.radio.disabled.checked .first-icon {
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox.disabled.checked .second-icon,
.radio.disabled.checked .second-icon {
  opacity: 1;
  filter: alpha(opacity=100);
  color: #e6e8ea;
}
.checkbox.primary .icons,
.radio.primary .icons {
  color: #34495e;
}
.checkbox.primary.checked,
.radio.primary.checked {
  color: #1abc9c;
}
.checkbox.primary.checked .icons,
.radio.primary.checked .icons {
  color: #1abc9c;
}
.checkbox.primary.disabled,
.radio.primary.disabled {
  cursor: default;
  color: #bdc3c7;
}
.checkbox.primary.disabled .icons,
.radio.primary.disabled .icons {
  color: #bdc3c7;
}
.checkbox.primary.disabled.checked .icons,
.radio.primary.disabled.checked .icons {
  color: #bdc3c7;
}
.radio + .radio,
.checkbox + .checkbox {
  margin-top: 10px;
}
.tagsinput {
  background: white;
  border: 2px solid #ebedef;
  border-radius: 6px;
  height: 100px;
  margin-bottom: 18px;
  padding: 6px 1px 1px 6px;
  overflow-y: auto;
  text-align: left;
}
.tagsinput .tag {
  border-radius: 4px;
  background-color: #ebedef;
  color: #7b8996;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  overflow: hidden;
  line-height: 15px;
  padding: 6px 13px 8px 19px;
  position: relative;
  vertical-align: middle;
  -webkit-transition: 0.25s linear;
  transition: 0.25s linear;
}
.tagsinput .tag:hover {
  background-color: #16a085;
  color: #ffffff;
  padding-left: 12px;
  padding-right: 20px;
}
.tagsinput .tag:hover .tagsinput-remove-link {
  color: #ffffff;
  opacity: 1;
  display: block\9;
}
.tagsinput input {
  background: transparent;
  border: none;
  color: #34495e;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin: 0px;
  padding: 0 0 0 5px;
  outline: none !important;
  margin: 6px 5px 0 0;
  vertical-align: top;
  width: 12px;
}
.tagsinput-remove-link {
  bottom: 0;
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
  opacity: 0;
  padding: 7px 7px 5px 0;
  position: absolute;
  right: 0;
  text-align: right;
  text-decoration: none;
  top: 0;
  width: 100%;
  z-index: 2;
  display: none\9;
}
.tagsinput-remove-link:before {
  color: #ffffff;
  content: "\e00b";
  font-family: "Flat-UI-Icons";
}
.tagsinput-add-container {
  vertical-align: middle;
  display: inline-block;
}
.tagsinput-add {
  background-color: #d6dbdf;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 5px;
  padding: 7px 9px;
  vertical-align: top;
  -webkit-transition: 0.25s linear;
  transition: 0.25s linear;
}
.tagsinput-add:hover {
  background-color: #1abc9c;
}
.tagsinput-add:before {
  content: "\e009";
  font-family: "Flat-UI-Icons";
}
.tags_clear {
  clear: both;
  width: 100%;
  height: 0px;
}
.not_valid {
  background: #fbd8db !important;
  color: #90111a !important;
  margin-left: 5px !important;
}
.tagsinput-primary {
  border-color: #1abc9c;
}
.tagsinput-primary .tag {
  background-color: #1abc9c;
  color: #ffffff;
}
.twitter-typeahead {
  width: 100%;
}
.twitter-typeahead .tt-query,
.twitter-typeahead .tt-hint {
  border: 2px solid #bdc3c7;
  color: #34495e;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 1.467;
  padding: 8px 12px;
  height: 42px;
  -webkit-appearance: none;
  border-radius: 6px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
  transition: border .25s linear, color .25s linear, background-color .25s linear;
}
.twitter-typeahead .tt-query:-moz-placeholder,
.twitter-typeahead .tt-hint:-moz-placeholder {
  color: #b2bcc5;
}
.twitter-typeahead .tt-query::-moz-placeholder,
.twitter-typeahead .tt-hint::-moz-placeholder {
  color: #b2bcc5;
  opacity: 1;
}
.twitter-typeahead .tt-query:-ms-input-placeholder,
.twitter-typeahead .tt-hint:-ms-input-placeholder {
  color: #b2bcc5;
}
.twitter-typeahead .tt-query::-webkit-input-placeholder,
.twitter-typeahead .tt-hint::-webkit-input-placeholder {
  color: #b2bcc5;
}
.twitter-typeahead .tt-query.placeholder,
.twitter-typeahead .tt-hint.placeholder {
  color: #b2bcc5;
}
.form-group.focus .twitter-typeahead .tt-query,
.form-group.focus .twitter-typeahead .tt-hint,
.twitter-typeahead .tt-query:focus,
.twitter-typeahead .tt-hint:focus {
  border-color: #1abc9c;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.twitter-typeahead .tt-query[disabled],
.twitter-typeahead .tt-hint[disabled],
.twitter-typeahead .tt-query[readonly],
.twitter-typeahead .tt-hint[readonly],
fieldset[disabled] .twitter-typeahead .tt-query,
fieldset[disabled] .twitter-typeahead .tt-hint {
  background-color: #f4f6f6;
  border-color: #d5dbdb;
  color: #d5dbdb;
  cursor: default;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.twitter-typeahead .tt-query.flat,
.twitter-typeahead .tt-hint.flat {
  border-color: transparent;
}
.twitter-typeahead .tt-query.flat:hover,
.twitter-typeahead .tt-hint.flat:hover {
  border-color: #bdc3c7;
}
.twitter-typeahead .tt-query.flat:focus,
.twitter-typeahead .tt-hint.flat:focus {
  border-color: #1abc9c;
}
.twitter-typeahead .tt-query + .input-icon,
.twitter-typeahead .tt-hint + .input-icon {
  position: absolute;
  top: 2px;
  right: 2px;
  line-height: 37px;
  vertical-align: middle;
  font-size: 20px;
  color: #b2bcc5;
  background-color: #ffffff;
  padding: 0 12px 0 0;
  border-radius: 6px;
}
.twitter-typeahead .tt-dropdown-menu {
  width: 100%;
  margin-top: 5px;
  border: 2px solid #1abc9c;
  padding: 5px 0;
  background-color: #ffffff;
  border-radius: 6px;
}
.twitter-typeahead .tt-suggestion p {
  padding: 6px 14px;
  font-size: 14px;
  line-height: 1.429;
  margin: 0;
}
.twitter-typeahead .tt-suggestion:first-child p,
.twitter-typeahead .tt-suggestion:last-child p {
  padding: 6px 14px;
}
.twitter-typeahead .tt-suggestion.tt-is-under-cursor {
  color: #fff;
  background-color: #16a085;
}
.progress {
  background: #ebedef;
  border-radius: 32px;
  height: 12px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.progress-bar {
  background: #1abc9c;
  line-height: 12px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.progress-bar-success {
  background-color: #2ecc71;
}
.progress-bar-warning {
  background-color: #f1c40f;
}
.progress-bar-danger {
  background-color: #e74c3c;
}
.progress-bar-info {
  background-color: #3498db;
}
.ui-slider {
  background: #ebedef;
  border-radius: 32px;
  height: 12px;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
}
.ui-slider-handle {
  background-color: #16a085;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  position: absolute;
  width: 18px;
  z-index: 2;
  -webkit-transition: background 0.25s;
  transition: background 0.25s;
}
.ui-slider-handle:hover,
.ui-slider-handle:focus {
  background-color: #48c9b0;
  outline: none;
}
.ui-slider-handle:active {
  background-color: #16a085;
}
.ui-slider-range {
  background-color: #1abc9c;
  display: block;
  height: 100%;
  position: absolute;
  z-index: 1;
}
.ui-slider-segment {
  background-color: #d9dbdd;
  border-radius: 50%;
  height: 6px;
  width: 6px;
}
.ui-slider-value {
  float: right;
  font-size: 13px;
  margin-top: 12px;
}
.ui-slider-value.first {
  clear: left;
  float: left;
}
.ui-slider-horizontal .ui-slider-handle {
  margin-left: -9px;
  top: -3px;
}
.ui-slider-horizontal .ui-slider-handle[style*="100"] {
  margin-left: -15px;
}
.ui-slider-horizontal .ui-slider-range {
  border-radius: 30px 0 0 30px;
}
.ui-slider-horizontal .ui-slider-segment {
  float: left;
  margin: 3px -6px 0 0;
}
.ui-slider-vertical {
  width: 12px;
}
.ui-slider-vertical .ui-slider-handle {
  margin-left: -3px;
  margin-bottom: -11px;
  top: auto;
}
.ui-slider-vertical .ui-slider-range {
  width: 100%;
  bottom: 0;
  border-radius: 0 0 30px 30px;
}
.ui-slider-vertical .ui-slider-segment {
  position: absolute;
  right: 3px;
}
.ui-spinner {
  display: inline-block;
  overflow: hidden;
  padding: 0 37px 0 0;
  position: relative;
  vertical-align: middle;
}
@media (max-width: 768px) {
  .ui-spinner {
    display: block;
  }
}
.ui-spinner .ui-button-text {
  display: block;
  line-height: normal;
}
.ui-spinner.focus .ui-spinner-input {
  color: #1abc9c;
  border-color: #1abc9c;
}
.ui-spinner.focus .ui-spinner-button {
  background-color: #1abc9c;
}
.ui-spinner.focus .ui-spinner-button:hover {
  background-color: #16a085;
}
.ui-spinner.focus .ui-spinner-up {
  border-bottom-color: #16a085;
}
.ui-spinner-input {
  width: 55px;
  border-radius: 6px 0 0 6px;
  text-align: center;
  padding: 6px 12px;
  border-right-width: 0 !important;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.3;
}
.ui-spinner-input:-moz-placeholder {
  color: #718191;
}
.ui-spinner-input::-moz-placeholder {
  color: #718191;
  opacity: 1;
}
.ui-spinner-input:-ms-input-placeholder {
  color: #718191;
}
.ui-spinner-input::-webkit-input-placeholder {
  color: #718191;
}
.ui-spinner-input.placeholder {
  color: #718191;
}
@media (max-width: 768px) {
  .ui-spinner-input {
    width: 100%;
  }
}
.ui-spinner-input.input-hg + .ui-spinner-button {
  height: 28px;
  line-height: 25px;
}
.ui-spinner-input.input-hg + .ui-spinner-button + .ui-spinner-button {
  height: 25px;
  line-height: 25px;
}
.ui-spinner-input.input-lg + .ui-spinner-button {
  height: 24px;
  line-height: 21px;
}
.ui-spinner-input.input-lg + .ui-spinner-button + .ui-spinner-button {
  height: 21px;
  line-height: 21px;
}
.ui-spinner-input.input-sm {
  font-size: 18px;
  line-height: 1;
}
.ui-spinner-input.input-sm + .ui-spinner-button {
  height: 19px;
  line-height: 1;
}
.ui-spinner-input.input-sm + .ui-spinner-button + .ui-spinner-button {
  height: 17px;
  line-height: 1;
}
.input-group-addon + .ui-spinner .ui-spinner-input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.ui-spinner-button {
  cursor: default;
  display: block;
  font-size: .5em;
  line-height: 19px;
  height: 22px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  width: 37px;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  background-color: #bdc3c7;
  outline: none;
}
.ui-spinner-button:hover {
  background-color: #d1d5d8;
  color: #e8f8f5;
}
.ui-spinner-button .ui-icon {
  font-size: 0;
}
.ui-spinner-button .ui-icon:before {
  content: "\e001";
  font-family: "Flat-UI-Icons";
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}
.ui-spinner-up {
  border-bottom: 2px solid #d1d5d8;
  top: 0;
  border-radius: 0 6px 0 0;
}
.ui-spinner-down {
  bottom: 0;
  height: 20px;
  border-radius: 0 0 6px 0;
}
.ui-spinner-down .ui-icon:before {
  content: "\e005";
}
.ui-datepicker {
  width: 264px;
  background-color: #ffffff;
  display: none;
  margin: 10px 0 0 0;
  border: 7px solid #1abc9c;
  border-radius: 6px;
  -webkit-box-shadow: -14px 14px 0 rgba(52, 73, 94, 0.06);
  box-shadow: -14px 14px 0 rgba(52, 73, 94, 0.06);
}
.ui-datepicker .ui-datepicker-header {
  background-color: #1abc9c;
  color: #ffffff;
  cursor: default;
  margin: -7px -7px 2px;
  position: relative;
  border-radius: 6px 6px 0 0;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 0;
  width: 52px;
  height: 44px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}
.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:hover {
  background-color: #48c9b0;
}
.ui-datepicker .ui-datepicker-prev:active,
.ui-datepicker .ui-datepicker-next:active {
  background-color: #16a085;
}
.ui-datepicker .ui-datepicker-prev.ui-state-disabled,
.ui-datepicker .ui-datepicker-next.ui-state-disabled {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.75);
  cursor: default;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.ui-datepicker .ui-datepicker-prev {
  left: 0;
  border-right: 2px solid #5fd0ba;
  border-radius: 6px 0 0 0;
}
.ui-datepicker .ui-datepicker-next {
  right: 0;
  border-left: 2px solid #5fd0ba;
  border-radius: 0 6px 0 0;
}
.ui-datepicker .ui-datepicker-title {
  margin: 0 52px;
  line-height: 44px;
  text-align: center;
  font-size: 15px;
}
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -5px;
  top: 50%;
  margin-top: -6px;
  text-indent: -9999px;
  width: 0;
  height: 0;
  content: "";
}
.ui-datepicker .ui-icon-circle-triangle-w {
  border: inset transparent;
  border-width: 7px 12px 7px 0;
  border-right-color: #ffffff;
}
.ui-datepicker .ui-icon-circle-triangle-e {
  border: outset transparent;
  border-width: 7px 0 7px 12px;
  border-left-color: #ffffff;
}
.ui-datepicker table {
  font-size: 14px;
  margin: 0 0 3px;
  width: 100%;
}
.ui-datepicker th {
  min-width: 34px;
  height: 30px;
  text-align: center;
  font-weight: bold;
  border: 0;
  padding: 1px;
  vertical-align: middle;
}
.ui-datepicker td {
  border: 0;
  padding: 1px;
  text-align: center;
  vertical-align: middle;
}
.ui-datepicker td span,
.ui-datepicker td a {
  display: block;
  text-decoration: none;
  color: #34495e;
  height: 30px;
  line-height: 30px;
  min-width: 34px;
}
.ui-datepicker td a {
  border-radius: 6px;
}
.ui-datepicker td a:hover {
  background-color: #d7dbdd;
  color: #34495e;
}
.ui-datepicker .ui-state-active {
  background-color: #1abc9c;
  color: #ffffff;
}
.ui-datepicker .ui-datepicker-other-month a {
  color: #c2c8cf;
}
.ui-datepicker select {
  font-size: 1em;
  margin: 1px 0;
}
.ui-datepicker select.ui-datepicker-month-year {
  width: 100%;
}
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  width: 49%;
}
.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: .7em 0 0 0;
  padding: 0 .2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}
.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: .5em .2em .4em;
  cursor: pointer;
  padding: .2em .6em .3em .6em;
  width: auto;
  overflow: visible;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}
.ui-datepicker:before {
  content: "";
  border-color: transparent transparent #1abc9c;
  border-style: solid;
  border-width: 0 6px 6px;
  height: 0;
  margin-top: 0;
  opacity: 1;
  position: absolute;
  left: 12px;
  top: -13px;
  width: 0;
  z-index: 10;
}
.ui-datepicker.ui-datepicker-multi {
  width: auto;
}
.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}
.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto .4em;
}
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0;
}
.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}
.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}
.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}
.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}
.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}
.pager {
  background-color: #34495e;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
}
.pager li:first-child > a,
.pager li:first-child > span {
  border-left: none;
  border-radius: 6px 0 0 6px;
}
.pager li > a,
.pager li > span {
  background: none;
  border: none;
  border-left: 2px solid #2c3e50;
  color: #ffffff;
  padding: 9px 15px 10px;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 0 6px 6px 0;
  line-height: 1.313;
}
.pager li > a:hover,
.pager li > span:hover,
.pager li > a:focus,
.pager li > span:focus {
  background-color: #2c3e50;
}
.pager li > a:active,
.pager li > span:active {
  background-color: #2c3e50;
}
.pager li > a [class*="fui-"] + span,
.pager li > span [class*="fui-"] + span {
  margin-left: 8px;
}
.pager li > a span + [class*="fui-"],
.pager li > span span + [class*="fui-"] {
  margin-left: 8px;
}
.pagination {
  position: relative;
}
@media (max-width: 767px) {
  .pagination {
    display: block;
  }
}
.pagination ul {
  background: #d6dbdf;
  color: #ffffff;
  padding: 0;
  margin: 0;
  display: inline-block;
  border-radius: 6px;
}
@media (max-width: 767px) {
  .pagination ul {
    height: 41px;
    padding: 0 55px 0 52px;
    overflow: auto;
    white-space: nowrap;
    border-radius: 6px;
  }
}
.pagination ul li {
  display: inline-block;
  margin-right: -3px;
  vertical-align: middle;
}
.pagination ul li.active > span {
  background-color: #1abc9c;
  color: #ffffff;
}
.pagination ul li.active.previous > a,
.pagination ul li.active.next > a,
.pagination ul li.active.previous > span,
.pagination ul li.active.next > span {
  margin: 0;
}
.pagination ul li.active.previous > a,
.pagination ul li.active.next > a,
.pagination ul li.active.previous > span,
.pagination ul li.active.next > span,
.pagination ul li.active.previous > a:hover,
.pagination ul li.active.next > a:hover,
.pagination ul li.active.previous > span:hover,
.pagination ul li.active.next > span:hover,
.pagination ul li.active.previous > a:focus,
.pagination ul li.active.next > a:focus,
.pagination ul li.active.previous > span:focus,
.pagination ul li.active.next > span:focus {
  background-color: #1abc9c;
  color: #ffffff;
}
.pagination ul li:first-child > a,
.pagination ul li:first-child > span {
  border-radius: 6px 0 0 6px;
}

.pagination ul li:last-child > a,
.pagination ul li:last-child > span,
.pagination ul li:last-child > a:hover,
.pagination ul li:last-child > span:hover,
.pagination ul li:last-child > a:focus,
.pagination ul li:last-child > span:focus {
  border-radius: 0 6px 6px 0;
}

@media (max-width: 767px) {
  .pagination ul li.next,
  .pagination ul li.previous {
    background-color: #d6dbdf;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    border-radius: 0 6px 6px 0;
  }
  .pagination ul li.previous {
    left: 0;
    right: auto;
    border-radius: 6px 0 0 6px;
  }
}
.pagination ul li > a,
.pagination ul li > span {
  display: inline-block;
  font-size: 12px;
  min-height: 36px;
  min-width: 36px;
  outline: none;
  padding: 10px;
  text-align: center;
  margin: 0 2px;
}
.pagination ul li > a:hover,
.pagination ul li > span:hover,
.pagination ul li > a:focus,
.pagination ul li > span:focus {
  background-color: #1abc9c;
}
.pagination ul li > a:active,
.pagination ul li > span:active {
  background-color: #1abc9c;
  color: #ffffff;
}
.pagination > .btn.previous,
.pagination > .btn.next {
  margin-right: 8px;
  font-size: 14px;
  line-height: 1.429;
  padding-left: 23px;
  padding-right: 23px;
}
.pagination > .btn.previous [class*="fui-"],
.pagination > .btn.next [class*="fui-"] {
  font-size: 16px;
  margin-left: -2px;
  margin-top: -2px;
}
.pagination > .btn.next {
  margin-left: 8px;
  margin-right: 0;
}
.pagination > .btn.next [class*="fui-"] {
  margin-right: -2px;
  margin-left: 4px;
}
@media (max-width: 767px) {
  .pagination > .btn {
    display: block;
    margin: 0;
    width: 50%;
  }
  .pagination > .btn:first-child {
    border-bottom: 2px solid #dfe2e5;
    border-radius: 6px 0 0;
  }
  .pagination > .btn:first-child.btn-primary {
    border-bottom-color: #48c9b0;
  }
  .pagination > .btn:first-child.btn-danger {
    border-bottom-color: #ec7063;
  }
  .pagination > .btn:first-child.btn-warning {
    border-bottom-color: #f4d03f;
  }
  .pagination > .btn:first-child.btn-success {
    border-bottom-color: #58d68d;
  }
  .pagination > .btn:first-child.btn-info {
    border-bottom-color: #5dade2;
  }
  .pagination > .btn:first-child.btn-inverse {
    border-bottom-color: #5d6d7e;
  }
  .pagination > .btn:first-child > [class*="fui"] {
    margin-left: -20px;
  }
  .pagination > .btn + ul {
    padding: 0;
    text-align: center;
    border-radius: 0 0 6px 6px;
  }
  .pagination > .btn + ul + .btn {
    border-bottom: 2px solid #dfe2e5;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 6px 0 0;
  }
  .pagination > .btn + ul + .btn.btn-primary {
    border-bottom-color: #48c9b0;
  }
  .pagination > .btn + ul + .btn.btn-danger {
    border-bottom-color: #ec7063;
  }
  .pagination > .btn + ul + .btn.btn-warning {
    border-bottom-color: #f4d03f;
  }
  .pagination > .btn + ul + .btn.btn-success {
    border-bottom-color: #58d68d;
  }
  .pagination > .btn + ul + .btn.btn-info {
    border-bottom-color: #5dade2;
  }
  .pagination > .btn + ul + .btn.btn-inverse {
    border-bottom-color: #5d6d7e;
  }
  .pagination > .btn + ul + .btn > [class*="fui"] {
    margin-right: -20px;
  }
  .pagination ul {
    display: block;
  }
  .pagination ul > li > a {
    border-radius: 0;
  }
}
.pagination-danger ul {
  background-color: #e74c3c;
}
.pagination-danger ul li.previous > a {
  border-right-color: #ef897e;
}
.pagination-danger ul li > a,
.pagination-danger ul li > span {
  border-left-color: #ef897e;
}
.pagination-danger ul li > a:hover,
.pagination-danger ul li > span:hover,
.pagination-danger ul li > a:focus,
.pagination-danger ul li > span:focus {
  background-color: #ec7063;
}
.pagination-danger ul li > a:active,
.pagination-danger ul li > span:active {
  background-color: #c44133;
}
.pagination-danger ul li.active > a,
.pagination-danger ul li.active > span {
  background-color: #c44133;
}
.pagination-danger ul li.pagination-dropdown.dropup .dropdown-arrow {
  border-top-color: #e74c3c;
}
.pagination-success ul {
  background-color: #2ecc71;
}
.pagination-success ul li.previous > a {
  border-right-color: #75dda1;
}
.pagination-success ul li > a,
.pagination-success ul li > span {
  border-left-color: #75dda1;
}
.pagination-success ul li > a:hover,
.pagination-success ul li > span:hover,
.pagination-success ul li > a:focus,
.pagination-success ul li > span:focus {
  background-color: #58d68d;
}
.pagination-success ul li > a:active,
.pagination-success ul li > span:active {
  background-color: #27ad60;
}
.pagination-success ul li.active > a,
.pagination-success ul li.active > span {
  background-color: #27ad60;
}
.pagination-success ul li.pagination-dropdown.dropup .dropdown-arrow {
  border-top-color: #2ecc71;
}
.pagination-warning ul {
  background-color: #f1c40f;
}
.pagination-warning ul li.previous > a {
  border-right-color: #f6d861;
}
.pagination-warning ul li > a,
.pagination-warning ul li > span {
  border-left-color: #f6d861;
}
.pagination-warning ul li > a:hover,
.pagination-warning ul li > span:hover,
.pagination-warning ul li > a:focus,
.pagination-warning ul li > span:focus {
  background-color: #f5d313;
}
.pagination-warning ul li > a:active,
.pagination-warning ul li > span:active {
  background-color: #cda70d;
}
.pagination-warning ul li.active > a,
.pagination-warning ul li.active > span {
  background-color: #cda70d;
}
.pagination-warning ul li.pagination-dropdown.dropup .dropdown-arrow {
  border-top-color: #f1c40f;
}
.pagination-info ul {
  background-color: #3498db;
}
.pagination-info ul li.previous > a {
  border-right-color: #79bbe7;
}
.pagination-info ul li > a,
.pagination-info ul li > span {
  border-left-color: #79bbe7;
}
.pagination-info ul li > a:hover,
.pagination-info ul li > span:hover,
.pagination-info ul li > a:focus,
.pagination-info ul li > span:focus {
  background-color: #5dade2;
}
.pagination-info ul li > a:active,
.pagination-info ul li > span:active {
  background-color: #2c81ba;
}
.pagination-info ul li.active > a,
.pagination-info ul li.active > span {
  background-color: #2c81ba;
}
.pagination-info ul li.pagination-dropdown.dropup .dropdown-arrow {
  border-top-color: #3498db;
}
.pagination-inverse ul {
  background-color: #34495e;
}
.pagination-inverse ul li.previous > a {
  border-right-color: #798795;
}
.pagination-inverse ul li > a,
.pagination-inverse ul li > span {
  border-left-color: #798795;
}
.pagination-inverse ul li > a:hover,
.pagination-inverse ul li > span:hover,
.pagination-inverse ul li > a:focus,
.pagination-inverse ul li > span:focus {
  background-color: #415b76;
}
.pagination-inverse ul li > a:active,
.pagination-inverse ul li > span:active {
  background-color: #2c3e50;
}
.pagination-inverse ul li.active > a,
.pagination-inverse ul li.active > span {
  background-color: #2c3e50;
}
.pagination-inverse ul li.pagination-dropdown.dropup .dropdown-arrow {
  border-top-color: #34495e;
}
.pagination-minimal > ul > li:first-child {
  border-radius: 6px 0 0 6px;
}
.pagination-minimal > ul > li:first-child.previous + li > a,
.pagination-minimal > ul > li:first-child.previous + li > span {
  border-left-width: 5px;
}
.pagination-minimal > ul > li:last-child {
  border-radius: 0 6px 6px 0;
}
.pagination-minimal > ul > li.previous > a,
.pagination-minimal > ul > li.next > a,
.pagination-minimal > ul > li.previous > span,
.pagination-minimal > ul > li.next > span {
  background: transparent;
  border: none;
  border-right: 2px solid #e4e7ea;
  margin: 0 9px 0 0;
  padding: 12px 17px;
  border-radius: 6px 0 0 6px;
}
.pagination-minimal > ul > li.previous > a,
.pagination-minimal > ul > li.next > a,
.pagination-minimal > ul > li.previous > span,
.pagination-minimal > ul > li.next > span,
.pagination-minimal > ul > li.previous > a:hover,
.pagination-minimal > ul > li.next > a:hover,
.pagination-minimal > ul > li.previous > span:hover,
.pagination-minimal > ul > li.next > span:hover,
.pagination-minimal > ul > li.previous > a:focus,
.pagination-minimal > ul > li.next > a:focus,
.pagination-minimal > ul > li.previous > span:focus,
.pagination-minimal > ul > li.next > span:focus {
  border-color: #e4e7ea !important;
}
@media (max-width: 767px) {
  .pagination-minimal > ul > li.previous > a,
  .pagination-minimal > ul > li.next > a,
  .pagination-minimal > ul > li.previous > span,
  .pagination-minimal > ul > li.next > span {
    margin-right: 0;
  }
}
.pagination-minimal > ul > li.next {
  margin-left: 9px;
}
.pagination-minimal > ul > li.next > a,
.pagination-minimal > ul > li.next > span {
  border-left: 2px solid #e4e7ea;
  border-right: none;
  margin: 0;
  border-radius: 0 6px 6px 0;
}
.pagination-minimal > ul > li.active > a,
.pagination-minimal > ul > li.active > span {
  background-color: #ffffff;
  border-color: #ffffff;
  border-width: 2px !important;
  color: #d6dbdf;
  margin: 10px 5px 9px;
}
.pagination-minimal > ul > li.active > a:hover,
.pagination-minimal > ul > li.active > span:hover,
.pagination-minimal > ul > li.active > a:focus,
.pagination-minimal > ul > li.active > span:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #d6dbdf;
}
.pagination-minimal > ul > li.active.previous,
.pagination-minimal > ul > li.active.next {
  border-color: #e4e7ea;
}
.pagination-minimal > ul > li.active.previous {
  margin-right: 6px;
}
.pagination-minimal > ul > li > a,
.pagination-minimal > ul > li > span {
  background: #ffffff;
  border: 5px solid #d6dbdf;
  color: #ffffff;
  line-height: 16px;
  margin: 7px 2px 6px;
  min-width: 0;
  min-height: 16px;
  padding: 0 4px;
  border-radius: 50px;
  -webkit-transition: background .2s ease-out, border-color 0s ease-out, color .2s ease-out;
  transition: background .2s ease-out, border-color 0s ease-out, color .2s ease-out;
}
.pagination-minimal > ul > li > a:hover,
.pagination-minimal > ul > li > span:hover,
.pagination-minimal > ul > li > a:focus,
.pagination-minimal > ul > li > span:focus {
  background-color: #1abc9c;
  border-color: #1abc9c;
  color: #ffffff;
  -webkit-transition: background .2s ease-out, border-color .2s ease-out, color .2s ease-out;
  transition: background .2s ease-out, border-color .2s ease-out, color .2s ease-out;
}
.pagination-minimal > ul > li > a:active,
.pagination-minimal > ul > li > span:active {
  background-color: #16a085;
  border-color: #16a085;
}
.pagination-plain {
  font-size: 16px;
  font-weight: 700;
  list-style-type: none;
  margin: 0 0 20px;
  padding: 0;
}
.pagination-plain > li {
  display: inline;
}
.pagination-plain > li.previous {
  padding-right: 23px;
}
.pagination-plain > li.next {
  padding-left: 20px;
}
.pagination-plain > li.active > a {
  color: #d3d7da;
}
.pagination-plain > li > a {
  padding: 0 5px;
}
@media (max-width: 767px) {
  .pagination-plain {
    height: 50px;
    overflow: hidden;
    text-align: center;
  }
  .pagination-plain > li.previous {
    display: block;
    margin-bottom: 10px;
    text-align: left;
    width: 50%;
  }
  .pagination-plain > li.next {
    float: right;
    margin-top: -50px;
    text-align: right;
    width: 50%;
  }
}
.pagination-dropdown ul {
  min-width: 67px;
  width: auto;
  left: 50%;
  margin-left: -34px;
}
.pagination-dropdown ul li {
  display: block;
  margin-right: 0;
}
.pagination-dropdown ul li:first-child > a,
.pagination-dropdown ul li:first-child > span {
  border-radius: 6px 6px 0 0;
}
.pagination-dropdown ul li:last-child > a,
.pagination-dropdown ul li:last-child > span {
  border-radius: 0 0 6px 6px !important;
}
.pagination-dropdown ul li > a,
.pagination-dropdown ul li > span {
  border-left: none;
  display: block;
  float: none;
  padding: 8px 10px 7px;
  text-align: center;
  min-height: 0;
}
.pagination-dropdown.dropup {
  position: relative;
}
.pagination-dropdown.dropup .dropdown-arrow {
  border-top-color: #09678d;
  left: 93px;
}
.tooltip {
  font-size: 14px;
  line-height: 1.286;
}
.tooltip.in {
  opacity: 1;
}
.tooltip.top {
  padding-bottom: 9px;
}
.tooltip.top .tooltip-arrow {
  border-top-color: #34495e;
  border-width: 9px 9px 0;
  bottom: 0;
  margin-left: -9px;
}
.tooltip.right .tooltip-arrow {
  border-right-color: #34495e;
  border-width: 9px 9px 9px 0;
  margin-top: -9px;
  left: -3px;
}
.tooltip.bottom {
  padding-top: 8px;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #34495e;
  border-width: 0 9px 9px;
  margin-left: -9px;
  top: -1px;
}
.tooltip.left .tooltip-arrow {
  border-left-color: #34495e;
  border-width: 9px 0 9px 9px;
  margin-top: -9px;
  right: -3px;
}
.tooltip-inner {
  background-color: #34495e;
  line-height: 1.286;
  padding: 12px 12px;
  text-align: center;
  width: 183px;
  border-radius: 6px;
}
.tooltip-light.bottom .tooltip-arrow {
  border-bottom-color: #edeff1;
}
.tooltip-light.left .tooltip-arrow {
  border-left-color: #edeff1;
}
.tooltip-light.right .tooltip-arrow {
  border-right-color: #edeff1;
}
.tooltip-light.top .tooltip-arrow {
  border-top-color: #edeff1;
}
.tooltip-light .tooltip-inner {
  background-color: #edeff1;
  color: #34495e;
}
.dropdown-menu {
  background-color: #f3f4f5;
  border: none;
  display: block;
  margin-top: 8px;
  opacity: 0;
  padding: 0;
  visibility: hidden;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}
.open > .dropdown-menu {
  margin-top: 18px !important;
  opacity: 1;
  visibility: visible;
}
.dropdown-menu li:first-child dt + a {
  border-radius: 0;
}
.dropdown-menu li:first-child > a {
  border-radius: 6px 6px 0 0;
  padding-top: 8px;
}
.dropdown-menu li:last-child > a {
  border-radius: 0 0 6px 6px;
  padding-bottom: 10px;
}
.dropdown-menu li.active > a,
.dropdown-menu li.selected > a,
.dropdown-menu li.active > a.highlighted,
.dropdown-menu li.selected > a.highlighted {
  background: #1abc9c;
  color: #ffffff;
}
.dropdown-menu li.active > a:hover,
.dropdown-menu li.selected > a:hover,
.dropdown-menu li.active > a.highlighted:hover,
.dropdown-menu li.selected > a.highlighted:hover,
.dropdown-menu li.active > a:focus,
.dropdown-menu li.selected > a:focus,
.dropdown-menu li.active > a.highlighted:focus,
.dropdown-menu li.selected > a.highlighted:focus {
  background: #16a085;
  color: #ffffff;
}
.dropdown-menu li > a {
  color: rgba(52, 73, 94, 0.75);
  padding: 6px 15px 8px;
  text-decoration: none;
  -webkit-transition: background-color 0.25s;
  transition: background-color 0.25s;
}
.dropdown-menu li > a:before,
.dropdown-menu li > a:after {
  content: " ";
  /* 1 */

  display: table;
  /* 2 */

}
.dropdown-menu li > a:after {
  clear: both;
}
.dropdown-menu li > a:hover,
.dropdown-menu li > a:active,
.dropdown-menu li > a:focus {
  background: #e1e4e7;
  color: inherit;
  outline: none;
}
.dropdown-menu li > a.highlighted {
  background: #c9cfd4;
  color: #ffffff;
}
.dropdown-menu li > a.highlighted:hover,
.dropdown-menu li > a.highlighted:focus {
  background: #bac1c8;
  color: #ffffff;
}
.dropdown-menu li > a:before {
  float: right;
  margin-top: 3px;
}
.dropdown-menu li dt {
  font-weight: 300;
  margin-bottom: 3px;
  margin-top: 12px;
  padding: 0 15px;
}
@media (max-width: 767px) {
  .dropdown-menu {
    border-radius: 0 0 6px 6px !important;
  }
}
.dropdown-menu .divider {
  margin-top: 3px;
  margin-bottom: 3px;
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  margin-bottom: 8px;
}
.dropup .dropdown-arrow,
.navbar-fixed-bottom .dropdown .dropdown-arrow {
  border-bottom: none;
  border-top: 8px outset #f3f4f5;
  bottom: 100%;
  top: auto;
}
.navbar-fixed-bottom .nav > li > ul:before {
  border-bottom: none;
  border-top: 9px outset #34495e;
  bottom: 4px;
  top: auto;
}
.open.dropup > .dropdown-menu {
  margin-bottom: 18px;
  margin-left: 7px;
}
.open.dropup > .dropdown-arrow {
  margin-bottom: 10px;
}
.open.dropup > .dropdown-arrow.dropdown-arrow-inverse {
  border-top-color: #34495e;
}
.open > .dropdown-arrow {
  margin-top: 11px;
  opacity: 1;
}
.dropdown-arrow {
  border-style: solid;
  border-width: 0 8px 7px;
  border-color: transparent transparent #f3f4f5 transparent;
  height: 0;
  margin-top: 0;
  opacity: 0;
  position: absolute;
  right: 19px;
  top: 100%;
  width: 0;
  z-index: 10;
  -webkit-transform: rotate(360deg);
  -webkit-transition: 0.25s;
  transition: 0.25s;
}
.dropdown-inverse {
  background-color: #34495e;
  color: #cccccc;
  padding: 4px 0 6px;
}
.dropdown-inverse li {
  margin: 0 4px -2px;
}
.dropdown-inverse li:first-child > a,
.dropdown-inverse li:last-child > a {
  border-radius: 2px;
  padding-bottom: 7px;
  padding-top: 5px;
}
.dropdown-inverse li:first-child dt + a,
.dropdown-inverse li:last-child dt + a {
  border-radius: 2px;
}
.dropdown-inverse li.active > a,
.dropdown-inverse li.selected > a {
  background: #1abc9c;
  color: #ffffff;
  position: relative;
  z-index: 1;
}
.dropdown-inverse li dt {
  padding-left: 11px;
  padding-right: 11px;
}
.dropdown-inverse li .divider {
  margin-left: 11px;
  margin-right: 11px;
}
.dropdown-inverse li > a {
  border-radius: 2px;
  color: #ffffff;
  padding: 5px 11px 7px;
}
.dropdown-inverse li > a:hover,
.dropdown-inverse li > a:active,
.dropdown-inverse li > a:focus {
  background: #2c3e50;
}
.dropdown-inverse li > a.highlighted {
  background: #526476;
}
.dropdown-inverse li > a.highlighted:hover,
.dropdown-inverse li > a.highlighted:focus {
  background: #677786;
}
.dropdown-inverse li .divider {
  background-color: #526476;
  border-bottom-color: #526476;
}
@media (max-width: 767px) {
  .dropdown-menu li a [class*="icon-"] {
    display: none;
  }
}
.table {
  font-size: 16px;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 9px 25px;
  line-height: 1.231;
  border-top: 2px solid #eef0f0;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th {
  color: #526476;
  border-bottom: 2px solid #eef0f0;
}
.table [class*="fui-"] {
  vertical-align: baseline;
  font-size: 20px;
  margin: -5px 0;
  position: relative;
  top: 2px;
}
.table .checkbox,
.table .radio {
  margin: 0 0 0 -9px;
}
.table .checkbox.no-label,
.table .radio.no-label {
  padding-left: 0;
}
.table .selected-row > td {
  background-color: #f2fbfa;
}
.table img {
  vertical-align: top;
}
.table-bordered {
  border: 2px solid #eef0f0;
  font-size: 14px;
}
.table-bordered > thead > tr th,
.table-bordered > tbody > tr th,
.table-bordered > tfoot > tr th,
.table-bordered > thead > tr td,
.table-bordered > tbody > tr td,
.table-bordered > tfoot > tr td {
  border: 2px solid #eef0f0 !important;
  padding-top: 8px;
  padding-bottom: 8px;
  vertical-align: middle;
}
.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr.selected-row > td,
.table-striped > tbody > tr:nth-child(even) > th,
.table-striped > tbody > tr.selected-row > th {
  background-color: #f9fafb;
}
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: transparent;
}
.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background-color: #f9fafb;
}
.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: #ecfaf2;
}
.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr.success:hover > th {
  background-color: #e4f8ed;
}
.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: #edf6fc;
}
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr.info:hover > th {
  background-color: #e4f1fa;
}
.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background-color: #fefae9;
}
.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #fdf7e0;
}
.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: #fdefed;
}
.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #fce7e4;
}
.label {
  font-size: 72%;
  font-weight: normal;
  padding: .15em .5em .2em;
}
.label[href]:hover,
.label[href]:focus {
  color: #ffffff;
}
.btn .label {
  position: relative;
  top: -1px;
}
.label-default {
  background-color: #ebedef;
  color: #7b8996;
}
.label-default[href] {
  color: #7b8996;
}
.label-default[href]:hover,
.label-default[href]:focus {
  color: #7b8996;
  background-color: #e1e4e7;
}
.label-inverse {
  background-color: #485b6e;
  color: #ffffff;
}
.label-inverse[href] {
  color: #ffffff;
}
.label-inverse[href]:hover,
.label-inverse[href]:focus {
  color: #ffffff;
  background-color: #a4adb7;
}
.label-success {
  background-color: #2ecc71;
  color: #ffffff;
}
.label-success[href] {
  color: #ffffff;
}
.label-success[href]:hover,
.label-success[href]:focus {
  color: #ffffff;
  background-color: #27ad60;
}
.label-important {
  background-color: #e74c3c;
  color: #ffffff;
}
.label-important[href] {
  color: #ffffff;
}
.label-important[href]:hover,
.label-important[href]:focus {
  color: #ffffff;
  background-color: #c44133;
}
.label-warning {
  background-color: #f1c40f;
  color: #ffffff;
}
.label-warning[href] {
  color: #ffffff;
}
.label-warning[href]:hover,
.label-warning[href]:focus {
  color: #ffffff;
  background-color: #cda70d;
}
.label-info {
  background-color: #3498db;
  color: #ffffff;
}
.label-info[href] {
  color: #ffffff;
}
.label-info[href]:hover,
.label-info[href]:focus {
  color: #ffffff;
  background-color: #2c81ba;
}
.label-primary {
  background-color: #1abc9c;
  color: #ffffff;
}
.label-primary[href] {
  color: #ffffff;
}
.label-primary[href]:hover,
.label-primary[href]:focus {
  color: #ffffff;
  background-color: #16a085;
}
.badge {
  font-size: 13px;
  font-weight: normal;
  color: #ffffff;
  line-height: 1.615;
  background-color: #d8dce0;
  border-radius: 4px;
  padding: 0 8px;
}
.btn .badge {
  position: relative;
  top: -1px;
}
a.list-group-item.active > .badge,
.nav-pills > .active > a > .badge,
.nav-list > .active > a > .badge {
  color: #9aa4af;
  background-color: #ffffff;
}
.alert {
  color: #34495e;
  padding: 18px 45px 14px 30px;
  text-shadow: none;
  background-color: #f9f8f3;
  border: 2px solid #ebece8;
  font-size: 16px;
  line-height: 1.625;
  border-radius: 6px;
}
.alert a {
  color: #f1c40f;
  font-weight: bold;
}
.alert a:hover {
  text-decoration: underline;
}
.alert a.btn {
  color: #ffffff;
  text-decoration: none;
}
.alert a.btn:active,
.alert a.btn.active {
  color: rgba(255, 255, 255, 0.75);
}
.alert h4 {
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 4px;
}
.alert p {
  font-size: inherit;
  line-height: inherit;
}
.alert .close {
  position: relative;
  top: 3px;
  right: -31px;
  line-height: 18px;
  font-size: 18px;
  color: #34495e;
}
.alert .btn {
  margin-bottom: 15px;
  margin-right: 15px;
  margin-top: 15px;
}
@media (max-width: 767px) {
  .alert .btn {
    display: block;
    min-width: auto;
    margin-bottom: 10px;
    margin-right: -15px;
  }
}
.alert-success {
  background-color: #eff7f3;
  border-color: #e1ebe9;
}
.alert-success a {
  color: #16a085;
}
.alert-info {
  background-color: #f7f9fa;
  border-color: #e9ecef;
}
.alert-info a {
  color: #3498db;
}
.alert-error {
  background-color: #f8f5f5;
  border-color: #ebe9ea;
}
.alert-error a {
  color: #e74c3c;
}
.bottom-menu {
  background: #ebedef;
  color: #bcc3ca;
  padding: 39px 0 42px;
}
.bottom-menu .navbar-brand {
  font-size: 24px;
  margin-top: -4px;
  padding: 0;
}
.bottom-menu .title {
  font-size: 13px;
  font-weight: 700;
  margin-top: 0;
}
.bottom-menu a {
  color: inherit;
}
.active .bottom-menu a,
.bottom-menu a:hover,
.bottom-menu a:focus {
  color: #1abc9c;
}
.bottom-menu .label,
.bottom-menu .badge {
  vertical-align: middle;
}
.bottom-menu .bottom-links {
  font-size: 14px;
  line-height: 1.286;
}
.bottom-menu .bottom-links:before,
.bottom-menu .bottom-icons:before,
.bottom-menu .bottom-links:after,
.bottom-menu .bottom-icons:after {
  content: " ";
  /* 1 */

  display: table;
  /* 2 */

}
.bottom-menu .bottom-links:after,
.bottom-menu .bottom-icons:after {
  clear: both;
}
.bottom-icons {
  font-size: 16px;
  line-height: 1;
}
.bottom-menu-inverse {
  background-color: #34495e;
  color: #677786;
}
.bottom-menu-large {
  padding-bottom: 56px;
  padding-top: 60px;
}
.bottom-menu-large .navbar-brand {
  margin-top: -2px;
}
.bottom-menu-large .bottom-links li {
  float: none;
  line-height: 22px;
  margin-right: 0;
}
.bottom-menu-large .title {
  margin-bottom: 10px;
}
.bottom-links,
.bottom-icons {
  margin: 0;
  padding: 0;
  list-style: none;
}
.bottom-links li,
.bottom-icons li {
  display: block;
  float: left;
  margin: 0 18px 0 0;
}
@media (max-width: 767px) {
  .bottom-menu .navbar-brand,
  .bottom-menu .bottom-links {
    margin-bottom: 30px;
  }
  .bottom-menu .navbar-brand {
    padding: 15px;
    float: none;
  }
  .bottom-menu .bottom-links li {
    float: none;
    margin-bottom: 2px;
  }
  .bottom-menu [class*="span"]:last-child .bottom-links {
    margin-bottom: 0;
  }
}
.nav .dropdown-toggle .caret {
  border-bottom-color: #7f8c9a;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #7f8c9a;
  border-top-width: 6px;
}
.nav-pills > li:first-child > a {
  border-left: none;
  border-radius: 6px 0 0 6px;
}
.nav-pills > li:last-child > a {
  border-radius: 0 6px 6px 0;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover {
  background-color: #16a085;
}
@media (max-width: 767px) {
  .nav-pills > li {
    float: none;
    display: block;
  }
  .nav-pills > li:first-child > a {
    border-radius: 6px 6px 0 0;
  }
  .nav-pills > li:last-child > a {
    border-radius: 0 0 6px 6px;
  }
}
.nav-pills > li > a {
  background-color: #1abc9c;
  border-left: 2px solid #16a085;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  outline: none;
  padding: 12px 21px 13px;
  border-radius: 0;
}
.nav-pills > li > a:hover,
.nav-pills > li > a:focus {
  background-color: #48c9b0;
}
.nav-pills > li > a:active {
  background-color: #16a085;
}
@media (max-width: 767px) {
  .nav-pills > li > a {
    border-left: none;
    display: block;
  }
}
.nav-pills > li + li {
  margin-left: 0;
}
.nav-tabs {
  margin-bottom: 20px;
  border-bottom-width: 2px;
  border-bottom-color: #e1e4e7;
}
.nav-tabs.nav-append-content {
  border-bottom: none;
  margin-bottom: 0;
}
.nav-tabs.nav-append-content > li.active:first-child:before {
  background-color: #ffffff;
  border-left: 2px solid #e1e4e7;
  content: "";
  height: 6px;
  left: 0;
  position: absolute;
  bottom: -4px;
  width: 6px;
  z-index: 2;
}
.nav-tabs > li {
  margin-bottom: -2px;
  position: relative;
}
.nav-tabs > li.active {
  z-index: 2;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border-color: #e1e4e7;
  border-bottom-color: #ffffff;
  border-width: 2px;
  color: #7f8c9a;
}
.nav-tabs > li.active .dropdown-toggle,
.nav-tabs > li.active .dropdown-toggle:hover,
.nav-tabs > li.active .dropdown-toggle:focus {
  color: #7f8c9a;
}
.nav-tabs > li.active .dropdown-toggle .caret,
.nav-tabs > li.active .dropdown-toggle:hover .caret,
.nav-tabs > li.active .dropdown-toggle:focus .caret {
  border-bottom-color: #7f8c9a;
  border-top-color: #7f8c9a;
}
.nav-tabs > li.open a.dropdown-toggle,
.nav-tabs > li.open.active a.dropdown-toggle,
.nav-tabs > li.open a.dropdown-toggle:hover,
.nav-tabs > li.open.active a.dropdown-toggle:hover {
  background-color: #f3f4f5;
  border-color: #e1e4e7;
  border-bottom-color: transparent;
  color: #7f8c9a;
}
.nav-tabs > li.open a.dropdown-toggle .caret,
.nav-tabs > li.open.active a.dropdown-toggle .caret,
.nav-tabs > li.open a.dropdown-toggle:hover .caret,
.nav-tabs > li.open.active a.dropdown-toggle:hover .caret {
  border-bottom-color: #7f8c9a;
  border-top-color: #7f8c9a;
}
.nav-tabs > li.open .dropdown-menu,
.nav-tabs > li.open.active .dropdown-menu {
  margin-top: -2px;
}
.nav-tabs > li.open.active {
  z-index: 1003;
}
.nav-tabs > li > a {
  border-width: 2px;
  font-size: 14px;
  font-weight: 700;
  padding: 7px 21px 8px;
  color: #7f8c9a;
  border-radius: 6px 6px 0 0;
  -webkit-transition: none;
  transition: none;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  background-color: transparent;
  border-color: transparent;
  border-bottom-color: transparent;
  outline: none;
  color: #1abc9c;
}
.nav-tabs > li .dropdown-toggle {
  position: relative;
  z-index: 1002;
}
.nav-tabs > li .dropdown-toggle:hover .caret,
.nav-tabs > li .dropdown-toggle:focus .caret {
  border-bottom-color: #1abc9c;
  border-top-color: #1abc9c;
}
.nav-tabs > li .dropdown-menu {
  border: 2px solid #e1e4e7;
  margin-top: -10px;
  border-radius: 0 6px 6px 6px;
}
.nav-tabs > li .dropdown-menu li:first-child > a {
  border-radius: 0 4px 0 0;
}
.nav-tabs > li .dropdown-menu li:last-child > a {
  border-radius: 0 0 4px 4px;
}
.nav-tabs > li .dropdown-menu li a {
  border-radius: 0;
}
.nav-tabs > li .open .dropdown-toggle {
  background-color: #f3f4f5;
}
.tab-content {
  border: 2px solid #ddd;
  border-radius: 6px;
  margin-bottom: 20px;
  padding: 20px 20px 5px;
  position: relative;
  z-index: 1;
}
.nav-list {
  padding-left: 13px;
  padding-right: 13px;
}
.nav-list .nav-header {
  margin-left: -13px;
  margin-right: -13px;
  padding: 0 13px;
  font-weight: 700;
  line-height: 1.538;
}
.nav-list > li {
  margin-bottom: 3px;
}
.nav-list > li.active > a,
.nav-list > li.active > a:hover,
.nav-list > li.active > a:focus {
  background-color: #ebedef;
  color: #526476;
}
.nav-list > li.divider {
  height: 2px;
  border-bottom: none;
  margin: 13px -13px 17px 0;
  background-color: #ebedef;
}
.nav-list > li > a {
  border-radius: 4px;
  font-size: 15px;
  line-height: 1.333;
  font-weight: 700;
  margin-left: -13px;
  margin-right: -13px;
  padding: 6px 13px 8px;
  text-shadow: none;
}
.nav-list .badge.pull-right {
  margin: 1px -4px -1px 0;
}
.nav-header {
  color: #aeb6bf;
  font-size: 13px;
  text-transform: uppercase;
}
.nav-list-vivid {
  background-color: #edeff1;
  border-radius: 4px;
  padding-bottom: 2px;
}
.nav-list-vivid > li {
  margin-bottom: 2px;
}
.nav-list-vivid > li.divider {
  background-color: transparent;
  margin: 3px 0 0;
}
.nav-list-vivid > li.active > a,
.nav-list-vivid > li.active > a:hover,
.nav-list-vivid > li.active > a:focus {
  background-color: #1abc9c;
  color: #ffffff;
}
.nav-list-vivid > li > a {
  border-radius: 0;
  color: #526476;
  font-size: 14px;
}
.nav-list-vivid > li > a:hover,
.nav-list-vivid > li > a:focus {
  color: #16a085;
}
.nav-list-vivid .nav-header:first-child {
  padding-top: 10px;
}
.navbar {
  font-size: 16px;
  min-height: 53px;
  margin-bottom: 30px;
  border: none;
  border-radius: 6px;
}
@media (min-width: 768px) {
  .navbar-header {
    float: left;
  }
}
.navbar-collapse {
  box-shadow: none;
  padding-right: 21px;
  padding-left: 21px;
}
@media (min-width: 768px) {
  .navbar-collapse .navbar-nav.navbar-left:first-child {
    margin-left: -21px;
  }
  .navbar-collapse .navbar-nav.navbar-left:first-child > li:first-child a {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .navbar-collapse .navbar-nav.navbar-right:last-child {
    margin-right: -21px;
  }
  .navbar > .container .navbar-collapse .navbar-nav.navbar-right:last-child {
    margin-right: -36px;
  }
  .navbar-collapse .navbar-nav.navbar-right:last-child > .dropdown:last-child > a {
    border-radius: 0 6px 6px 0;
  }
  .navbar-collapse .navbar-form.navbar-right:last-child {
    margin-right: -17px;
  }
  .navbar-fixed-top .navbar-collapse .navbar-form.navbar-right:last-child,
  .navbar-fixed-bottom .navbar-collapse .navbar-form.navbar-right:last-child {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .navbar-collapse .navbar-nav.navbar-right:last-child {
    margin-bottom: 3px;
  }
}
.container > .navbar-header,
.container-fluid > .navbar-header,
.container > .navbar-collapse,
.container-fluid > .navbar-collapse {
  margin-right: -21px;
  margin-left: -21px;
}
@media (min-width: 768px) {
  .container > .navbar-header,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }
}
.navbar-static-top {
  z-index: 1000;
  border-width: 0;
  border-radius: 0;
}
.navbar-fixed-top,
.navbar-fixed-bottom {
  z-index: 1030;
  border-radius: 0;
}
.navbar-fixed-top {
  border-width: 0;
}
.navbar-fixed-bottom {
  margin-bottom: 0;
  border-width: 0;
}
.navbar-brand {
  font-size: 24px;
  line-height: 1.042;
  font-weight: 700;
  padding: 14px 21px;
}
.navbar-brand > [class*="fui-"] {
  font-size: 19px;
  line-height: 1.263;
  vertical-align: top;
}
@media (min-width: 768px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: -21px;
  }
}
.navbar-toggle {
  border: none;
  color: #34495e;
  margin: 0 0 0 21px;
  padding: 0 21px;
  height: 53px;
  line-height: 53px;
}
.navbar-toggle:before {
  color: #16a085;
  content: "\e00c";
  font-family: "Flat-UI-Icons";
  font-size: 22px;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: color 0.25s linear;
  transition: color 0.25s linear;
}
.navbar-toggle:hover,
.navbar-toggle:focus {
  outline: none;
}
.navbar-toggle:hover:before,
.navbar-toggle:focus:before {
  color: #1abc9c;
}
.navbar-toggle .icon-bar {
  display: none;
}
@media (min-width: 768px) {
  .navbar-toggle {
    display: none;
  }
}
.navbar-nav {
  margin: 0;
}
.navbar-nav > li > a {
  font-size: 16px;
  padding: 15px 21px;
  line-height: 23px;
  font-weight: 700;
}
.navbar-nav .dropdown-toggle .caret {
  border-top-color: #16a085;
  border-bottom-color: #16a085;
  border-width: 8px 6px 0;
  margin-left: 12px;
}
.navbar-nav > li > a:hover,
.navbar-nav > li > a:focus,
.navbar-nav .open > a:focus,
.navbar-nav .open > a:hover {
  background-color: transparent;
}
.navbar-nav > li > a:hover .caret,
.navbar-nav > li > a:focus .caret,
.navbar-nav .open > a:focus .caret,
.navbar-nav .open > a:hover .caret {
  border-top-color: #1abc9c;
  border-bottom-color: #1abc9c;
}
.navbar-nav [class^="fui-"] {
  line-height: 20px;
  position: relative;
  top: 1px;
}
.navbar-nav .visible-sm > [class^="fui-"],
.navbar-nav .visible-xs > [class^="fui-"] {
  margin-left: 12px;
}
@media (max-width: 767px) {
  .navbar-nav {
    margin: 0 -21px;
  }
  .navbar-nav .open .dropdown-menu > li > a,
  .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 7px 15px 7px 31px !important;
  }
  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 23px;
  }
  .navbar-nav > li > a {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}
.navbar-input {
  height: 35px;
  padding: 5px 10px;
  font-size: 13px;
  line-height: 1.4;
  border-radius: 6px;
}
select.navbar-input {
  height: 35px;
  line-height: 35px;
}
textarea.navbar-input,
select[multiple].navbar-input {
  height: auto;
}
.navbar-form {
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 19px;
  padding-left: 19px;
  padding-top: 9px;
  padding-bottom: 9px;
}
@media (max-width: 767px) {
  .navbar-form .form-group {
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .navbar-form {
    margin: 3px -21px;
    width: auto;
  }
}
@media (min-width: 768px) {
  .navbar-nav + .navbar-form.navbar-left,
  .navbar-form.navbar-right:last-child {
    width: 260px;
  }
}
.navbar-form .form-control,
.navbar-form .input-group-addon,
.navbar-form .btn {
  height: 35px;
  padding: 5px 10px;
  font-size: 13px;
  line-height: 1.4;
  border-radius: 6px;
}
select.navbar-form .form-control,
select.navbar-form .input-group-addon,
select.navbar-form .btn {
  height: 35px;
  line-height: 35px;
}
textarea.navbar-form .form-control,
textarea.navbar-form .input-group-addon,
textarea.navbar-form .btn,
select[multiple].navbar-form .form-control,
select[multiple].navbar-form .input-group-addon,
select[multiple].navbar-form .btn {
  height: auto;
}
.navbar-form .input-group .form-control:first-child,
.navbar-form .input-group-addon:first-child,
.navbar-form .input-group-btn:first-child > .btn,
.navbar-form .input-group-btn:first-child > .dropdown-toggle,
.navbar-form .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.navbar-form .input-group .form-control:last-child,
.navbar-form .input-group-addon:last-child,
.navbar-form .input-group-btn:last-child > .btn,
.navbar-form .input-group-btn:last-child > .dropdown-toggle,
.navbar-form .input-group-btn:first-child > .btn:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.navbar-form .form-control {
  font-size: 15px;
  border-radius: 5px;
  display: table-cell;
}
.navbar-form .form-group + .btn {
  font-size: 15px;
  border-radius: 5px;
  margin-left: 4px;
}
@media (max-width: 767px) {
  .navbar-form .form-group {
    margin-bottom: 0;
  }
  .navbar-form .form-group + .btn {
    margin-top: 9px;
    margin-left: 0;
  }
}
.navbar-nav > li > .dropdown-menu {
  min-width: 100%;
  border-radius: 4px;
}
@media (max-width: 767px) {
  .navbar-nav > li > .dropdown-menu {
    -webkit-transition: all 0s;
    transition: all 0s;
    display: none;
  }
}
@media (max-width: 767px) {
  .navbar-nav > li.open > .dropdown-menu {
    margin-top: 0 !important;
    display: block;
  }
}
.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.navbar-nav > .open > .dropdown-toggle,
.navbar-nav > .open > .dropdown-toggle:focus,
.navbar-nav > .open > .dropdown-toggle:hover {
  background-color: transparent;
}
.navbar-text {
  font-size: 16px;
  line-height: 1.438;
  color: #34495e;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (min-width: 768px) {
  .navbar-text {
    margin-left: 21px;
    margin-right: 21px;
  }
  .navbar-text.navbar-right:last-child {
    margin-right: 0;
  }
}
.navbar-btn {
  margin-top: 6px;
  margin-bottom: 6px;
}
.navbar-btn.btn-sm {
  margin-top: 9px;
  margin-bottom: 8px;
}
.navbar-btn.btn-xs {
  margin-top: 14px;
  margin-bottom: 14px;
}
.navbar-unread,
.navbar-new {
  font-family: Helvetica, Arial, sans-serif;
  background-color: #1abc9c;
  border-radius: 50%;
  color: #ffffff;
  font-size: 0;
  font-weight: 700;
  height: 6px;
  line-height: 1;
  position: absolute;
  right: 12px;
  text-align: center;
  top: 35%;
  width: 6px;
  z-index: 10;
}
@media (max-width: 768px) {
  .navbar-unread,
  .navbar-new {
    position: static;
    float: right;
    margin: 0 0 0 10px;
  }
}
.active .navbar-unread,
.active .navbar-new {
  background-color: #ffffff;
  display: none;
}
.navbar-new {
  background-color: #e74c3c;
  font-size: 12px;
  height: 18px;
  line-height: 17px;
  margin: -6px -10px;
  min-width: 18px;
  padding: 0 1px;
  width: auto;
  -webkit-font-smoothing: subpixel-antialiased;
}
.navbar-default {
  background-color: #ecf0f1;
}
.navbar-default .navbar-brand {
  color: #34495e;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #1abc9c;
  background-color: transparent;
}
.navbar-default .navbar-toggle:before {
  color: #34495e;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: transparent;
}
.navbar-default .navbar-toggle:hover:before,
.navbar-default .navbar-toggle:focus:before {
  color: #1abc9c;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: #e5e9ea;
  border-width: 2px;
}
.navbar-default .navbar-nav > li > a {
  color: #34495e;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #1abc9c;
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #1abc9c;
  background-color: transparent;
}
.navbar-default .navbar-nav > .disabled > a,
.navbar-default .navbar-nav > .disabled > a:hover,
.navbar-default .navbar-nav > .disabled > a:focus {
  color: #cccccc;
  background-color: transparent;
}
.navbar-default .navbar-nav > .dropdown > a .caret {
  border-top-color: #34495e;
  border-bottom-color: #34495e;
}
.navbar-default .navbar-nav > .active > a .caret {
  border-top-color: #1abc9c;
  border-bottom-color: #1abc9c;
}
.navbar-default .navbar-nav > .dropdown > a:hover .caret,
.navbar-default .navbar-nav > .dropdown > a:focus .caret {
  border-top-color: #1abc9c;
  border-bottom-color: #1abc9c;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background-color: transparent;
  color: #1abc9c;
}
.navbar-default .navbar-nav > .open > a .caret,
.navbar-default .navbar-nav > .open > a:hover .caret,
.navbar-default .navbar-nav > .open > a:focus .caret {
  border-top-color: #1abc9c;
  border-bottom-color: #1abc9c;
}
@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #34495e;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #1abc9c;
    background-color: transparent;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #1abc9c;
    background-color: transparent;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #cccccc;
    background-color: transparent;
  }
}
.navbar-default .navbar-form .form-control {
  border-color: transparent;
}
.navbar-default .navbar-form .form-control:-moz-placeholder {
  color: #aeb6bf;
}
.navbar-default .navbar-form .form-control::-moz-placeholder {
  color: #aeb6bf;
  opacity: 1;
}
.navbar-default .navbar-form .form-control:-ms-input-placeholder {
  color: #aeb6bf;
}
.navbar-default .navbar-form .form-control::-webkit-input-placeholder {
  color: #aeb6bf;
}
.navbar-default .navbar-form .form-control.placeholder {
  color: #aeb6bf;
}
.navbar-default .navbar-form .form-control:focus {
  border-color: #1abc9c;
  color: #1abc9c;
}
.navbar-default .navbar-form .input-group-btn .btn {
  border-color: transparent;
  color: #919ba4;
}
.navbar-default .navbar-form .input-group.focus .form-control,
.navbar-default .navbar-form .input-group.focus .input-group-btn .btn {
  border-color: #1abc9c;
  color: #1abc9c;
}
.navbar-default .navbar-text {
  color: #34495e;
}
.navbar-default .navbar-text a {
  color: #34495e;
}
.navbar-default .navbar-text a:hover,
.navbar-default .navbar-text a:focus {
  color: #1abc9c;
}
.navbar-inverse {
  background-color: #34495e;
}
.navbar-inverse .navbar-brand {
  color: #ffffff;
}
.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-brand:focus {
  color: #1abc9c;
  background-color: transparent;
}
.navbar-inverse .navbar-toggle:before {
  color: #ffffff;
}
.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
  background-color: transparent;
}
.navbar-inverse .navbar-toggle:hover:before,
.navbar-inverse .navbar-toggle:focus:before {
  color: #1abc9c;
}
.navbar-inverse .navbar-collapse {
  border-color: #2f4154;
  border-width: 2px;
}
.navbar-inverse .navbar-nav > li > a {
  color: #ffffff;
}
.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:focus {
  color: #1abc9c;
  background-color: transparent;
}
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
  color: #ffffff;
  background-color: #1abc9c;
}
.navbar-inverse .navbar-nav > .disabled > a,
.navbar-inverse .navbar-nav > .disabled > a:hover,
.navbar-inverse .navbar-nav > .disabled > a:focus {
  color: #444444;
  background-color: transparent;
}
.navbar-inverse .navbar-nav > .dropdown > a:hover .caret,
.navbar-inverse .navbar-nav > .dropdown > a:focus .caret {
  border-top-color: #1abc9c;
  border-bottom-color: #1abc9c;
}
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
  background-color: #1abc9c;
  color: #ffffff;
  border-left-color: transparent;
}
.navbar-inverse .navbar-nav > .open > a .caret,
.navbar-inverse .navbar-nav > .open > a:hover .caret,
.navbar-inverse .navbar-nav > .open > a:focus .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.navbar-inverse .navbar-nav > .dropdown > a .caret {
  border-top-color: #4b6075;
  border-bottom-color: #4b6075;
}
.navbar-inverse .navbar-nav > .open > .dropdown-arrow {
  border-top-color: #34495e;
  border-bottom-color: #34495e;
}
.navbar-inverse .navbar-nav > .open > .dropdown-menu {
  background-color: #34495e;
  padding: 3px 4px;
}
.navbar-inverse .navbar-nav > .open > .dropdown-menu > li > a {
  color: #e1e4e7;
  border-radius: 4px;
  padding: 6px 9px;
}
.navbar-inverse .navbar-nav > .open > .dropdown-menu > li > a:hover,
.navbar-inverse .navbar-nav > .open > .dropdown-menu > li > a:focus {
  color: #ffffff;
  background-color: #1abc9c;
}
.navbar-inverse .navbar-nav > .open > .dropdown-menu > .divider {
  background-color: #2f4154;
  height: 2px;
  margin-left: -4px;
  margin-right: -4px;
}
@media (max-width: 767px) {
  .navbar-inverse .navbar-nav > li > a {
    border-left-width: 0;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #ffffff;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #1abc9c;
    background-color: transparent;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #ffffff;
    background-color: #1abc9c;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #444444;
    background-color: transparent;
  }
  .navbar-inverse .navbar-nav .dropdown-menu .divider {
    background-color: #2f4154;
  }
}
.navbar-inverse .navbar-form .form-control {
  color: #536a81;
  border-color: transparent;
  background-color: #293a4a;
}
.navbar-inverse .navbar-form .form-control:-moz-placeholder {
  color: #536a81;
}
.navbar-inverse .navbar-form .form-control::-moz-placeholder {
  color: #536a81;
  opacity: 1;
}
.navbar-inverse .navbar-form .form-control:-ms-input-placeholder {
  color: #536a81;
}
.navbar-inverse .navbar-form .form-control::-webkit-input-placeholder {
  color: #536a81;
}
.navbar-inverse .navbar-form .form-control.placeholder {
  color: #536a81;
}
.navbar-inverse .navbar-form .form-control:focus {
  border-color: #1abc9c;
  color: #1abc9c;
}
.navbar-inverse .navbar-form .btn {
  color: #ffffff;
  background-color: #1abc9c;
}
.navbar-inverse .navbar-form .btn:hover,
.navbar-inverse .navbar-form .btn.hover,
.navbar-inverse .navbar-form .btn:focus,
.navbar-inverse .navbar-form .btn:active,
.navbar-inverse .navbar-form .btn.active,
.open .dropdown-toggle.navbar-inverse .navbar-form .btn {
  color: #ffffff;
  background-color: #48c9b0;
  border-color: #48c9b0;
}
.navbar-inverse .navbar-form .btn:active,
.navbar-inverse .navbar-form .btn.active,
.open .dropdown-toggle.navbar-inverse .navbar-form .btn {
  background: #16a085;
  border-color: #16a085;
}
.navbar-inverse .navbar-form .btn.disabled,
.navbar-inverse .navbar-form .btn[disabled],
fieldset[disabled] .navbar-inverse .navbar-form .btn,
.navbar-inverse .navbar-form .btn.disabled:hover,
.navbar-inverse .navbar-form .btn[disabled]:hover,
fieldset[disabled] .navbar-inverse .navbar-form .btn:hover,
.navbar-inverse .navbar-form .btn.disabled.hover,
.navbar-inverse .navbar-form .btn[disabled].hover,
fieldset[disabled] .navbar-inverse .navbar-form .btn.hover,
.navbar-inverse .navbar-form .btn.disabled:focus,
.navbar-inverse .navbar-form .btn[disabled]:focus,
fieldset[disabled] .navbar-inverse .navbar-form .btn:focus,
.navbar-inverse .navbar-form .btn.disabled:active,
.navbar-inverse .navbar-form .btn[disabled]:active,
fieldset[disabled] .navbar-inverse .navbar-form .btn:active,
.navbar-inverse .navbar-form .btn.disabled.active,
.navbar-inverse .navbar-form .btn[disabled].active,
fieldset[disabled] .navbar-inverse .navbar-form .btn.active {
  background-color: #1abc9c;
  border-color: #1abc9c;
}
.navbar-inverse .navbar-form .btn .badge {
  color: #1abc9c;
  background-color: #ffffff;
}
.navbar-inverse .navbar-form .input-group-btn .btn {
  border-color: transparent;
  background-color: #293a4a;
  color: #526a82;
}
.navbar-inverse .navbar-form .input-group.focus .form-control,
.navbar-inverse .navbar-form .input-group.focus .input-group-btn .btn {
  border-color: #1abc9c;
  color: #1abc9c;
}
@media (max-width: 767px) {
  .navbar-inverse .navbar-form {
    border-color: #2f4154;
    border-width: 2px 0;
  }
}
.navbar-inverse .navbar-text {
  color: #ffffff;
}
.navbar-inverse .navbar-text a {
  color: #ffffff;
}
.navbar-inverse .navbar-text a:hover,
.navbar-inverse .navbar-text a:focus {
  color: #1abc9c;
}
.navbar-inverse .navbar-btn {
  color: #ffffff;
  background-color: #1abc9c;
}
.navbar-inverse .navbar-btn:hover,
.navbar-inverse .navbar-btn.hover,
.navbar-inverse .navbar-btn:focus,
.navbar-inverse .navbar-btn:active,
.navbar-inverse .navbar-btn.active,
.open .dropdown-toggle.navbar-inverse .navbar-btn {
  color: #ffffff;
  background-color: #48c9b0;
  border-color: #48c9b0;
}
.navbar-inverse .navbar-btn:active,
.navbar-inverse .navbar-btn.active,
.open .dropdown-toggle.navbar-inverse .navbar-btn {
  background: #16a085;
  border-color: #16a085;
}
.navbar-inverse .navbar-btn.disabled,
.navbar-inverse .navbar-btn[disabled],
fieldset[disabled] .navbar-inverse .navbar-btn,
.navbar-inverse .navbar-btn.disabled:hover,
.navbar-inverse .navbar-btn[disabled]:hover,
fieldset[disabled] .navbar-inverse .navbar-btn:hover,
.navbar-inverse .navbar-btn.disabled.hover,
.navbar-inverse .navbar-btn[disabled].hover,
fieldset[disabled] .navbar-inverse .navbar-btn.hover,
.navbar-inverse .navbar-btn.disabled:focus,
.navbar-inverse .navbar-btn[disabled]:focus,
fieldset[disabled] .navbar-inverse .navbar-btn:focus,
.navbar-inverse .navbar-btn.disabled:active,
.navbar-inverse .navbar-btn[disabled]:active,
fieldset[disabled] .navbar-inverse .navbar-btn:active,
.navbar-inverse .navbar-btn.disabled.active,
.navbar-inverse .navbar-btn[disabled].active,
fieldset[disabled] .navbar-inverse .navbar-btn.active {
  background-color: #1abc9c;
  border-color: #1abc9c;
}
.navbar-inverse .navbar-btn .badge {
  color: #1abc9c;
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .navbar-embossed > .navbar-collapse {
    border-radius: 6px;
    -webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
  }
  .navbar-embossed.navbar-inverse .navbar-nav .active > a,
  .navbar-embossed.navbar-inverse .navbar-nav .open > a {
    -webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
  }
}
.navbar-lg {
  min-height: 76px;
}
.navbar-lg .navbar-brand {
  line-height: 1;
  padding-top: 26px;
  padding-bottom: 26px;
}
.navbar-lg .navbar-brand > [class*="fui-"] {
  font-size: 24px;
  line-height: 1;
}
.navbar-lg .navbar-nav > li > a {
  font-size: 15px;
  line-height: 1.6;
}
@media (min-width: 768px) {
  .navbar-lg .navbar-nav > li > a {
    padding-top: 26px;
    padding-bottom: 26px;
  }
}
.navbar-lg .navbar-toggle {
  height: 76px;
  line-height: 76px;
}
.navbar-lg .navbar-form {
  padding-top: 20.5px;
  padding-bottom: 20.5px;
}
.navbar-lg .navbar-text {
  padding-top: 26.5px;
  padding-bottom: 26.5px;
}
.navbar-lg .navbar-btn {
  margin-top: 17.5px;
  margin-bottom: 17.5px;
}
.navbar-lg .navbar-btn.btn-sm {
  margin-top: 20.5px;
  margin-bottom: 20.5px;
}
.navbar-lg .navbar-btn.btn-xs {
  margin-top: 25.5px;
  margin-bottom: 25.5px;
}
.iconbar {
  background-color: #2c3e50;
  border-radius: 6px;
  float: left;
  margin: 0 20px 20px 0;
  -webkit-backface-visibility: hidden;
}
.iconbar:before,
.iconbar:after {
  content: " ";
  /* 1 */

  display: table;
  /* 2 */

}
.iconbar:after {
  clear: both;
}
.iconbar > ul {
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.iconbar > ul > li {
  margin: 0;
  padding: 0;
}
.iconbar > ul > li.active > a {
  color: #1abc9c;
}
.iconbar > ul > li:first-child > a {
  padding-top: 32px;
}
.iconbar > ul > li > a {
  color: #ffffff;
  display: block;
  font-size: 24px;
  line-height: 1;
  padding: 8px 30px 32px;
  position: relative;
  text-decoration: none;
}
.iconbar > ul > li > a:hover,
.iconbar > ul > li > a:focus {
  color: #1abc9c;
}
@media (max-width: 767px) {
  .iconbar > ul > li > a,
  .iconbar.iconbar-horizontal > ul > li > a,
  .iconbar > ul > li.active > a,
  .iconbar.iconbar-horizontal > ul > li.active > a {
    display: inline-block;
    padding: 25px;
  }
  .iconbar .iconbar-unread {
    right: 15px;
    top: 13px;
  }
  .iconbar.iconbar-horizontal {
    height: 70px;
    overflow: auto;
    width: 100%;
    white-space: nowrap;
  }
  .iconbar.iconbar-horizontal > ul > li {
    float: none;
    display: inline;
  }
  .iconbar.iconbar-horizontal .iconbar-unread {
    right: 15px;
  }
}
[class*="iconbar-stick-"] {
  border-radius: 0;
  margin: 0;
  position: fixed;
  z-index: 1001;
}
[class*="iconbar-stick-"] > ul {
  margin: 0;
}
.iconbar-stick-left {
  bottom: 0;
  left: 0;
  top: 0;
}
.iconbar-unread {
  background-color: #2a7aaf;
  border-radius: 50%;
  color: #ffffff;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.667;
  min-width: 20px;
  padding: 0 2px;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -2px;
  -webkit-font-smoothing: auto;
}
.iconbar-success {
  background-color: #2ecc71;
}
.iconbar-success > ul > li.active > a {
  color: #1e874b;
}
.iconbar-success > ul > li > a:hover,
.iconbar-success > ul > li > a:focus {
  color: #1e874b;
}
.iconbar-success .iconbar-unread {
  background-color: #1e874b;
}
.iconbar-danger {
  background-color: #e74c3c;
}
.iconbar-danger > ul > li.active > a {
  color: #983228;
}
.iconbar-danger > ul > li > a:hover,
.iconbar-danger > ul > li > a:focus {
  color: #983228;
}
.iconbar-danger .iconbar-unread {
  background-color: #983228;
}
.iconbar-warning {
  background-color: #f1c40f;
}
.iconbar-warning > ul > li.active > a {
  color: #9f810a;
}
.iconbar-warning > ul > li > a:hover,
.iconbar-warning > ul > li > a:focus {
  color: #9f810a;
}
.iconbar-warning .iconbar-unread {
  background-color: #9f810a;
}
.iconbar-info {
  background-color: #3498db;
}
.iconbar-info > ul > li.active > a {
  color: #226491;
}
.iconbar-info > ul > li > a:hover,
.iconbar-info > ul > li > a:focus {
  color: #226491;
}
.iconbar-info .iconbar-unread {
  background-color: #226491;
}
.iconbar-horizontal {
  clear: both;
  float: left;
}
.iconbar-horizontal > ul > li {
  float: left;
}
.iconbar-horizontal > ul > li:first-child > a {
  padding: 23px 32px 23px;
}
.iconbar-horizontal > ul > li > a {
  padding: 23px 32px 23px 8px;
}
.iconbar-horizontal .iconbar-unread {
  right: 23px;
  top: 13px;
}
.breadcrumb {
  background-color: #f3f4f5;
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  padding: 13px 20px;
  border-radius: 6px;
}
.breadcrumb > li {
  position: relative;
  text-shadow: none;
}
.breadcrumb > li:after {
  color: #bdc3c7;
  content: "\e002";
  display: inline-block;
  font-family: 'Flat-UI-Icons';
  font-size: 9.75px;
  margin: -4px 9px 0 13px;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.breadcrumb .active {
  color: #bdc3c7;
  cursor: default;
}
.breadcrumb .active:after {
  display: none;
}
.breadcrumb .active > a {
  color: inherit;
  cursor: inherit;
}
.breadcrumb > li + li:before {
  content: "";
  padding: 0;
}
.breadcrumb-text {
  color: #bdc3c7;
  margin-bottom: 20px;
}
.breadcrumb-text .caption {
  font-weight: 700;
  margin: 0 0 3px;
  font-size: 18px;
}
.breadcrumb-text .caption:after {
  content: "\e02c";
  display: inline-block;
  font-family: 'Flat-UI-Icons';
  font-weight: normal;
  font-variant: normal;
  margin-left: 5px;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
}
.breadcrumb-text p {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}
.breadcrumb-text p a:after {
  color: #bdc3c7;
  content: "/";
  font-weight: normal;
  margin: 0 4px 0 9px;
}
.popover {
  background-color: #edeff1;
  border: none;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.popover.right .arrow {
  border-right-color: #edeff1;
  left: -9px;
  margin-top: -9px;
}
.popover.left .arrow {
  border-left-color: #edeff1;
  right: -9px;
  margin-top: -9px;
}
.popover.bottom .arrow {
  border-bottom-color: #edeff1;
  margin-right: -9px;
  top: -9px;
}
.popover.top .arrow {
  border-top-color: #edeff1;
  bottom: -9px;
  margin-left: -9px;
}
.popover .arrow {
  border-width: 9px;
}
.popover .arrow:after {
  display: none;
}
.popover-title {
  background-color: #bcc3ca;
  color: #ffffff;
  font-size: 15px;
  line-height: 1.2;
  font-weight: 700;
  padding: 10px 20px 11px;
}
.popover-content {
  padding: 9px 20px 1px;
}
.popover-content p {
  font-size: 14px;
  line-height: 1.286;
}
.dialog {
  background-color: #edeff1;
  border-top: 2px solid #d6dbdf;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 16px 0 20px;
  position: relative;
  text-align: center;
}
.dialog .btn {
  vertical-align: baseline;
}
.dialog form {
  margin-bottom: 0;
}
.dialog form input,
.dialog form .btn {
  margin-bottom: 0;
  margin-left: 20px;
  vertical-align: top;
}
.dialog form input:first-child,
.dialog form .btn:first-child {
  margin-left: 0;
}
.dialog.dialog-success {
  background-color: #f0faf8;
  border-top-color: #e2edeb;
}
.dialog.dialog-danger {
  background-color: #fdefed;
  border-top-color: #fadbd8;
}
.dialog.dialog-warning {
  background-color: #fefae9;
  border-top-color: #fcf3cf;
}
.dialog.dialog-info {
  background-color: #edf6fc;
  border-top-color: #d6eaf8;
}
.dialog.dialog-inverse {
  background-color: #34495e;
  border-top-color: #2a3a4b;
  color: #ffffff;
}
@media (max-width: 767px) {
  .dialog {
    padding-left: 20px;
    padding-right: 20px;
  }
  .dialog form .btn,
  .dialog .btn {
    display: block;
    margin: 9px 0;
    min-width: auto;
    width: 100%;
  }
  .dialog form .btn:last-child,
  .dialog .btn:last-child {
    margin-bottom: 0;
  }
  .dialog form .btn + .btn,
  .dialog .btn + .btn {
    margin-top: 18px;
  }
  .dialog form input {
    margin: 9px 0;
  }
}
.modal {
  z-index: 1050;
}
.modal-content {
  background-color: #f9fafb;
  border: 2px solid #ebedef;
  border-radius: 7px;
  -webkit-background-clip: border-box;
  -moz-background-clip: border-box;
  background-clip: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.modal-backdrop {
  z-index: 1040;
  background-color: #243342;
}
.modal-backdrop.in {
  opacity: 0.95;
  filter: alpha(opacity=95);
}
.modal-header {
  padding: 17px 19px 15px 24px;
  border-bottom: 1px solid #ebedef;
}
.modal-header .close {
  margin: 5px 0 0;
  padding: 0;
  font-size: 18px;
  line-height: 1;
  color: #34495e;
}
.modal-title {
  margin: 0;
  font-size: 24px;
  line-height: 30px;
}
.modal-body {
  padding: 20px 24px 20px;
}
.modal-body p {
  font-size: 16px;
  line-height: 1.625;
}
.modal-footer {
  padding: 19px 22px 20px;
  margin-top: 0;
  background-color: #ebedef;
  border-top: none;
  border-radius: 0 0 7px 7px;
}
.modal-footer .btn + .btn {
  margin-left: 12px;
}
@media (max-width: 767px) {
  .modal-footer .btn {
    display: block;
    min-width: auto;
    margin-bottom: 15px;
  }
  .modal-footer .btn:last-child {
    margin-bottom: 0;
  }
  .modal-footer .btn + .btn {
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 624px;
    margin: 30px auto;
  }
  .modal-content {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .modal-sm {
    width: 300px;
  }
  .modal-lg {
    width: 900px;
  }
}
/* ============================================================
 * bootstrapSwitch v1.3 by Larentis Mattia @spiritualGuru
 * http://www.larentis.eu/switch/
 * ============================================================
 * Licensed under the Apache License, Version 2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 * ============================================================ */
.has-switch {
  border-radius: 30px;
  display: inline-block;
  cursor: pointer;
  line-height: 1.72222;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 80px;
  -webkit-mask: url('../images/switch/mask.png') 0 0 no-repeat;
  mask: url('../images/switch/mask.png') 0 0 no-repeat;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.has-switch.deactivate {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default !important;
}
.has-switch.deactivate label,
.has-switch.deactivate span {
  cursor: default !important;
}
.has-switch > div {
  width: 130px;
  position: relative;
  top: 0;
}
.has-switch > div.switch-animate {
  -webkit-transition: left 0.25s ease-out;
  transition: left 0.25s ease-out;
}
.has-switch > div.switch-off {
  left: -50px;
}
.has-switch > div.switch-off label {
  background-color: #7f8c9a;
  border-color: #bdc3c7;
  -webkit-box-shadow: -1px 0 0 rgba(255, 255, 255, 0.5);
  box-shadow: -1px 0 0 rgba(255, 255, 255, 0.5);
}
.has-switch > div.switch-on {
  left: 0;
}
.has-switch > div.switch-on label {
  background-color: #1abc9c;
}
.has-switch input[type=checkbox] {
  display: none;
}
.has-switch span {
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  float: left;
  height: 29px;
  line-height: 19px;
  margin: 0;
  padding-bottom: 6px;
  padding-top: 5px;
  position: relative;
  text-align: center;
  width: 50%;
  z-index: 1;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.has-switch span.switch-left {
  border-radius: 30px 0 0 30px;
  background-color: #34495e;
  color: #1abc9c;
  border-left: 1px solid transparent;
}
.has-switch span.switch-right {
  border-radius: 0 30px 30px 0;
  background-color: #bdc3c7;
  color: #ffffff;
  text-indent: 7px;
}
.has-switch span.switch-right [class*="fui-"] {
  text-indent: 0;
}
.has-switch label {
  border: 4px solid #34495e;
  border-radius: 50%;
  float: left;
  height: 29px;
  margin: 0 -15px;
  padding: 0;
  position: relative;
  vertical-align: middle;
  width: 29px;
  z-index: 100;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.switch-square {
  border-radius: 6px;
  -webkit-mask: url('../images/switch/mask.png') 0 0 no-repeat;
  mask: url('../images/switch/mask.png') 0 0 no-repeat;
}
.switch-square > div.switch-off label {
  border-color: #7f8c9a;
  border-radius: 6px 0 0 6px;
}
.switch-square span.switch-left {
  border-radius: 6px 0 0 6px;
}
.switch-square span.switch-left [class*="fui-"] {
  text-indent: -10px;
}
.switch-square span.switch-right {
  border-radius: 0 6px 6px 0;
}
.switch-square span.switch-right [class*="fui-"] {
  text-indent: 5px;
}
.switch-square label {
  border-radius: 0 6px 6px 0;
  border-color: #1abc9c;
}
.carousel-inner > .item.active > img,
.carousel-inner > .item.active > a > img {
  border-radius: 6px;
}
@media (max-width: 767px) {
  .carousel-inner > .item.active > img {
    border-radius: 6px 6px 0 0;
  }
}
.carousel-inner > .item.active.left > img,
.carousel-inner > .item.active.right > img,
.carousel-inner > .item.active.left > a > img,
.carousel-inner > .item.active.right > a > img {
  border-radius: 0;
}
.carousel-control {
  position: absolute;
  top: 45%;
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
  text-indent: 1px;
  border: 3px solid #ffffff;
  height: 40px;
  right: 15px;
  margin-top: -20px;
  text-align: center;
  width: 40px;
  outline: none !important;
  border-radius: 23px;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.carousel-control.left,
.carousel-control.right {
  background: none #2c3e50;
}
.carousel-control.left {
  text-indent: -1px;
  left: 15px;
}
.carousel-control.right {
  left: auto;
  right: 15px;
}
.carousel-indicators {
  bottom: auto;
  left: auto;
  margin: 0;
  right: 16px;
  top: 16px;
  width: auto;
  line-height: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.carousel-indicators li {
  vertical-align: top;
  border: none;
  width: 8px;
  height: 8px;
  margin: 0 0 0 6px;
  background-color: #2c3e50;
  background-color: rgba(44, 62, 80, 0.5);
}
.carousel-indicators li.active {
  background-color: #fff;
  width: 8px;
  height: 8px;
  margin: 0 0 0 6px;
}
.carousel-caption {
  padding: 20px 19px 22px;
  background: #243342;
  background: rgba(36, 51, 66, 0.7);
  left: 0;
  right: 0;
  bottom: 0;
  text-align: left;
  border-radius: 0 0 6px 6px;
  text-shadow: none;
}
.carousel-caption h3,
.carousel-caption p {
  color: #ffffff;
}
.carousel-caption h3 {
  font-size: 18px;
  line-height: 20px;
  margin: 0 0 5px;
}
.carousel-caption p {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .carousel-caption {
    position: static;
  }
}
.video-js {
  background-color: transparent;
  position: relative;
  padding: 0 0 47px 0;
  font-size: 10px;
  vertical-align: middle;
  border-radius: 6px 6px 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
}
.video-js .vjs-tech {
  height: 100%;
  width: 100%;
  display: block;
}
.video-js:-moz-full-screen {
  position: absolute;
}
.vjs-control-bar {
  position: relative;
  height: 47px;
  color: #ffffff;
  background: #273747;
  border-radius: 0 0 6px 6px;
}
.vjs-control-bar.vjs-fade-out {
  visibility: visible !important;
  opacity: 1 !important;
}
.video-js.vjs-fullscreen {
  position: fixed;
  overflow: hidden;
  z-index: 1000;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  _position: absolute;
}
.video-js:-webkit-full-screen {
  width: 100% !important;
  height: 100% !important;
}
.video-js .vjs-text-track-display {
  text-align: center;
  position: absolute;
  bottom: 4em;
  left: 1em;
  right: 1em;
  font-family: Helvetica, Arial, sans-serif;
}
.video-js .vjs-text-track {
  display: none;
  color: #ffffff;
  font-size: 1.4em;
  text-align: center;
  margin-bottom: .1em;
  background: #000;
  background: rgba(0, 0, 0, 0.5);
}
.video-js .vjs-subtitles {
  color: #fff;
}
.video-js .vjs-captions {
  color: #fc6;
}
.vjs-poster {
  margin: 0 auto;
  padding: 0;
  cursor: pointer;
  position: relative;
  width: 100%;
  max-height: 100%;
  border-radius: 6px 6px 0 0;
}
.vjs-tt-cue {
  display: block;
}
.vjs-fade-in {
  visibility: visible !important;
  opacity: 1 !important;
  -webkit-transition: visibility 0s linear 0s, opacity .3s linear;
  transition: visibility 0s linear 0s, opacity .3s linear;
}
.vjs-fade-out {
  visibility: hidden !important;
  opacity: 0 !important;
  -webkit-transition: visibility 0s linear 1.5s, opacity 1.5s linear;
  transition: visibility 0s linear 1.5s, opacity 1.5s linear;
}
.vjs-control {
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
  display: inline-block;
  height: 18px;
  width: 18px;
  vertical-align: middle;
}
.vjs-control:focus {
  outline: 0;
}
.vjs-control div {
  background-position: center center;
  background-repeat: no-repeat;
}
.vjs-control-text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.vjs-play-control {
  cursor: pointer;
  height: 47px;
  width: 58px;
}
.vjs-play-control div {
  position: relative;
  height: 47px;
}
.vjs-play-control div:before,
.vjs-play-control div:after {
  position: absolute;
  font-family: "Flat-UI-Icons";
  color: #1abc9c;
  font-size: 16px;
  top: 38%;
  left: 50%;
  margin: -0.55em 0 0 -0.5em;
  -webkit-transition: color .25s, opacity .25s;
  transition: color .25s, opacity .25s;
}
.vjs-play-control div:after {
  content: "\e03b";
}
.vjs-play-control div:before {
  content: "\e03c";
}
.vjs-paused .vjs-play-control:hover div:before {
  color: #16a085;
}
.vjs-paused .vjs-play-control div:after {
  opacity: 0;
  filter: alpha(opacity=0);
}
.vjs-paused .vjs-play-control div:before {
  opacity: 1;
  filter: alpha(opacity=100);
}
.vjs-playing .vjs-play-control:hover div:after {
  color: #16a085;
}
.vjs-playing .vjs-play-control div:after {
  opacity: 1;
  filter: alpha(opacity=100);
}
.vjs-playing .vjs-play-control div:before {
  opacity: 0;
  filter: alpha(opacity=0);
}
.vjs-rewind-control {
  width: 5em;
  cursor: pointer !important;
}
.vjs-rewind-control div {
  width: 19px;
  height: 16px;
  background: none transparent;
  margin: .5em auto 0;
}
.vjs-mute-control {
  background: url(../images/video/volume-full.png) center -48px no-repeat;
  background-size: 16px 64px;
  cursor: pointer !important;
}
.vjs-mute-control:hover div,
.vjs-mute-control:focus div {
  opacity: 0;
}
.vjs-mute-control.vjs-vol-0,
.vjs-mute-control.vjs-vol-0 div {
  background-image: url(../images/video/volume-off.png);
}
.vjs-mute-control div {
  background: #273747 url(../images/video/volume-full.png) no-repeat center 2px;
  background-size: 16px 64px;
  height: 18px;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.vjs-volume-control,
.vjs-volume-level,
.vjs-volume-handle,
.vjs-volume-bar {
  display: none;
}
.vjs-loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #ebedee;
  display: none;
  height: 16px;
  width: 16px;
  border-radius: 10px;
  -webkit-animation: sharp 2s ease infinite;
  -moz-animation: sharp 2s ease infinite;
  -o-animation: sharp 2s ease infinite;
  animation: sharp 2s ease infinite;
}
/* > Progress control */
.vjs-progress-control {
  height: 12px;
  position: absolute;
  left: 60px;
  right: 180px;
  width: auto;
  top: 18px;
  background: #425669;
  border-radius: 32px;
}
.vjs-progress-holder {
  position: relative;
  cursor: pointer !important;
  padding: 0;
  margin: 0;
  height: 12px;
}
.vjs-play-progress,
.vjs-load-progress {
  display: block;
  height: 12px;
  margin: 0;
  padding: 0;
  border-radius: 32px;
}
.vjs-play-progress {
  background: #1abc9c;
  left: -1px;
  position: absolute;
  top: 0;
}
.vjs-load-progress {
  background: #d6dbdf;
}
.vjs-load-progress[style*='100%'],
.vjs-load-progress[style*='99%'] {
  border-radius: 32px;
}
.vjs-seek-handle {
  background-color: #16a085;
  width: 18px;
  height: 18px;
  top: 0;
  position: absolute;
  margin: -3px 0 0 1px;
  border-radius: 50%;
  -webkit-transition: background-color 0.25s;
  transition: background-color 0.25s;
}
.vjs-seek-handle[style*='95.'] {
  margin-left: 3px;
}
.vjs-seek-handle[style='left: 0%;'] {
  margin-left: -2px;
}
.vjs-seek-handle:hover,
.vjs-seek-handle:focus {
  background-color: #148d75;
}
.vjs-seek-handle:active {
  background-color: #117a65;
}
/* < Progress control */
/*  Player time controls > */
.vjs-time-controls {
  font: 300 13px Helvetica, Arial, sans-serif;
  width: auto;
  height: auto;
  position: absolute;
}
.vjs-time-divider {
  color: #5d6d7e;
  font-size: 14px;
  position: absolute;
  right: 104px;
  top: 11px;
}
.vjs-remaining-time {
  display: none;
}
.vjs-current-time {
  right: 112px;
  top: 16px;
}
.vjs-duration {
  color: #5d6d7e;
  right: 75px;
  top: 16px;
}
/*  < Player time controls */
.vjs-mute-control {
  float: right;
  margin: 14px 0;
}
.vjs-fullscreen-control {
  background-image: url(../images/video/fullscreen.png);
  background-position: center -47px;
  background-size: 15px 64px;
  cursor: pointer;
  float: right;
  margin: 14px;
}
.vjs-fullscreen-control:hover div,
.vjs-fullscreen-control:focus div {
  opacity: 0;
}
.vjs-fullscreen-control div {
  height: 18px;
  background: url(../images/video/fullscreen.png) no-repeat center 2px;
  background-size: 15px 64px;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.vjs-menu-button {
  display: none !important;
}
@-webkit-keyframes sharp {
  0% {
    background: #e74c3c;
    border-radius: 10px;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    background: #ebedee;
    border-radius: 0;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    background: #e74c3c;
    border-radius: 10px;
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes sharp {
  0% {
    background: #e74c3c;
    border-radius: 10px;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    background: #ebedee;
    border-radius: 0;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    background: #e74c3c;
    border-radius: 10px;
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes sharp {
  0% {
    background: #e74c3c;
    border-radius: 10px;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    background: #ebedee;
    border-radius: 0;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    background: #e74c3c;
    border-radius: 10px;
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes sharp {
  0% {
    background: #e74c3c;
    border-radius: 10px;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    background: #ebedee;
    border-radius: 0;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    background: #e74c3c;
    border-radius: 10px;
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.last-col {
  overflow: hidden;
}
.ptn,
.pvn,
.pan {
  padding-top: 0 !important;
}
.ptx,
.pvx,
.pax {
  padding-top: 3px !important;
}
.pts,
.pvs,
.pas {
  padding-top: 5px !important;
}
.ptm,
.pvm,
.pam {
  padding-top: 10px !important;
}
.ptl,
.pvl,
.pal {
  padding-top: 20px !important;
}
.prn,
.phn,
.pan {
  padding-right: 0 !important;
}
.prx,
.phx,
.pax {
  padding-right: 3px !important;
}
.prs,
.phs,
.pas {
  padding-right: 5px !important;
}
.prm,
.phm,
.pam {
  padding-right: 10px !important;
}
.prl,
.phl,
.pal {
  padding-right: 20px !important;
}
.pbn,
.pvn,
.pan {
  padding-bottom: 0 !important;
}
.pbx,
.pvx,
.pax {
  padding-bottom: 3px !important;
}
.pbs,
.pvs,
.pas {
  padding-bottom: 5px !important;
}
.pbm,
.pvm,
.pam {
  padding-bottom: 10px !important;
}
.pbl,
.pvl,
.pal {
  padding-bottom: 20px !important;
}
.pln,
.phn,
.pan {
  padding-left: 0 !important;
}
.plx,
.phx,
.pax {
  padding-left: 3px !important;
}
.pls,
.phs,
.pas {
  padding-left: 5px !important;
}
.plm,
.phm,
.pam {
  padding-left: 10px !important;
}
.pll,
.phl,
.pal {
  padding-left: 20px !important;
}
.mtn,
.mvn,
.man {
  margin-top: 0px !important;
}
.mtx,
.mvx,
.max {
  margin-top: 3px !important;
}
.mts,
.mvs,
.mas {
  margin-top: 5px !important;
}
.mtm,
.mvm,
.mam {
  margin-top: 10px !important;
}
.mtl,
.mvl,
.mal {
  margin-top: 20px !important;
}
.mrn,
.mhn,
.man {
  margin-right: 0px !important;
}
.mrx,
.mhx,
.max {
  margin-right: 3px !important;
}
.mrs,
.mhs,
.mas {
  margin-right: 5px !important;
}
.mrm,
.mhm,
.mam {
  margin-right: 10px !important;
}
.mrl,
.mhl,
.mal {
  margin-right: 20px !important;
}
.mbn,
.mvn,
.man {
  margin-bottom: 0px !important;
}
.mbx,
.mvx,
.max {
  margin-bottom: 3px !important;
}
.mbs,
.mvs,
.mas {
  margin-bottom: 5px !important;
}
.mbm,
.mvm,
.mam {
  margin-bottom: 10px !important;
}
.mbl,
.mvl,
.mal {
  margin-bottom: 20px !important;
}
.mln,
.mhn,
.man {
  margin-left: 0px !important;
}
.mlx,
.mhx,
.max {
  margin-left: 3px !important;
}
.mls,
.mhs,
.mas {
  margin-left: 5px !important;
}
.mlm,
.mhm,
.mam {
  margin-left: 10px !important;
}
.mll,
.mhl,
.mal {
  margin-left: 20px !important;
}
