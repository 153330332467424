/*.btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0 !important;
}*/

button {
    background-color: transparent;
}

.navbar-brand {
    height: auto;
}

.navbar-nav.navbar-right:last-child {
    margin-right: 0;
}

.nav-tabs.nav-justified.nav-append-content > li.active:last-child:before {
    background-color: #ffffff;
    border-right: 2px solid #e1e4e7;
    content: "";
    height: 6px;
    right: 0;
    position: absolute;
    bottom: -4px;
    width: 6px;
    z-index: 2;
}

.nav-tabs.nav-justified>li>a {
    border-bottom-color: white;

}

.nav-tabs.nav-justified {
    margin-bottom: -2px;
}

.nav-tabs.nav-justified>.active>a, .nav-tabs.nav-justified>.active>a:hover, .nav-tabs.nav-justified>.active>a:focus {
    border-width: 2px;
}

.tab-content {
    padding: 20px;
}

.dropdown-menu {
	min-width: 0;
    transition: none;
}

.dropdown-arrow {
    transition: none;
}

.progress-bar {
    line-height: 20px;
}

.btn-group.select {
	margin-bottom: 0;
}

.form-control {
	font-family: Roboto, sans-serif !important;
}

.form-control[readonly] {
	background-color: white;
	border-color: #bdc3c7;
	color: #34495e;
}

label {
    line-height: 15px;
}

.dropdown-menu>li>a {
	white-space: normal;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    border-bottom: 2px solid #eef0f0;
}

.modal-lg {
    transition: 0.5s;
}

@media (min-width: 768px) {
    .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

.bootstrap-select .btn .caret {
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -3px;
}

.bootstrap-select.select-block {
    display: block;
    float: none;
    margin-left: 0;
    width: auto;
}

.bootstrap-select.select-block .btn {
    width: 100%;
}

.dropdown-inverse li > a {
    cursor: pointer;
}

.select.select-block .dropdown-menu {
    box-sizing: content-box;
}

.btn-group.select > .btn {
    float: none;
    text-align: left;
}

.btn-group.select > .btn > span {
    display: block;
    position: relative;
    width: 100%;
}

.btn-group.select > .btn > span > .caret {
    right: 0;
    margin-right: 0;
}

.select .btn .filter-option, .select .btn.btn-hg .filter-option, .select .btn.btn-xs .filter-option , .select .btn.btn-lg .filter-option {
  left: auto !important;
  right: auto !important;
  top: auto !important;
    position: relative !important;
    height: 11px !important;
}

select.form-control {
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    -moz-padding-start: 0;
    -moz-padding-end: 3px;
    padding-left: 3px;
    padding-right: 3px;
}

.pagination > ul {
    list-style: none;
}

.pagination > ul > li {
    margin-right: 0;
    float: left;
}

.dl-horizontal dt {
    text-overflow: clip;
    white-space: normal;
}

.btn-file > input[type="file"] {
    z-index: 2;
}

.btn[disabled], .btn.disabled {
    pointer-events: none;
}

.select .btn.btn-block {
    display: block;
    width: 100%;
}

small {
    line-height: inherit;
}

.popover {
    max-width: 400px;
}

.popover-content {
    padding: 10px;
}